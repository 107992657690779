import * as config from './base.json';
import jsonConfig from './JsonConfig';
import { get, merge } from 'lodash';

class Config {
  constructor () {
    this._config = config.default;
  }

  getConfig () {
    const configOverwrite = jsonConfig.getConfig();
    merge(this._config, configOverwrite);
  }

  getValue (field) {
    return get(this._config, field);
  }
}

export default new Config();
