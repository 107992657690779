import { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { NOTIFICATIONS_EVENTS_NAMES, NOTIFICATIONS_ORIGIN } from 'helpers/constants';
import { triggerNotifications } from 'services/notifications';
import { createRequest } from 'services/requestService';
import RequestsContext from 'context/RequestsContext';
import SessionContext from 'context/SessionContext';

const NewRequestContainer = ({ render }) => {
  const { forms, addRequest } = useContext(RequestsContext);
  const { currentUser } = useContext(SessionContext);
  const history = useHistory();

  const handleCreateRequest = async (formId) => {
    const newRequest = await createRequest(formId, get(currentUser, 'attributes'));
    addRequest(newRequest);
    triggerNotifications({
      eventName: NOTIFICATIONS_EVENTS_NAMES.REQUEST_DRAFT_CREATED,
      email: currentUser.attributes.email,
      accountId: currentUser.attributes['custom:accountId'],
      origin: NOTIFICATIONS_ORIGIN,
      data: {
        requestId: newRequest.id,
        title: newRequest.data.title,
      },
    });
    history.push(`/requests/${newRequest.id}`);
  };

  return render({ forms, createRequest: handleCreateRequest })
};

NewRequestContainer.propTypes = {
  render: PropTypes.func.isRequired,
};

export default NewRequestContainer;
