import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'synapse-ui';
import NewRequestContainer from 'components/RequestsPage/containers/NewRequestContainer';
import NewRequestModal from '../NewRequestModal';

const ModalTrigger = ({ renderTrigger }) => {
  const [isModalOpen, setModalOpen ] = useState(false);

  return (
    <NewRequestContainer
      render={({ forms, createRequest }) =>
        <React.Fragment>
          { renderTrigger({ openModal: () => setModalOpen(true) }) }
          <Modal
            open={isModalOpen}
            onClose={() => setModalOpen(false)}
            Content={NewRequestModal}
            contentData={{
              forms,
              createRequest,
            }}
          />
        </React.Fragment>
      }
    />
  )
};

ModalTrigger.propTypes = {
  renderTrigger: PropTypes.func.isRequired,
};

export default ModalTrigger;
