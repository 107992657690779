import axios from 'axios';

export const fetchRequestQuestions = async (requestId) => {
  const { data } = await axios.get(`requests/${requestId}/questions`);
  return data;
}
  
export const putQuestion = async ({ id, data }) => {
  const response = await axios.put(`questions/${id}`, {
    data,
  });
  return response.data;
}
