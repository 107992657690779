import React from 'react';
import Logo from 'components/components/Logo/Logo';
import { Branding } from 'synapse-ui';
import PromptText from './components/PromptText/PromptText';
import PasswordInputs from './components/PasswordInputs/PasswordInputs';
import GoToLogin from 'components/components/GoToLogin/GoToLogin';
import styles from './ResetPasswordPage.module.scss';

const ResetPasswordPage = () =>
  <div className={styles.resetPasswordPage}>
    <Logo />
    <PromptText />
    <PasswordInputs />
    <GoToLogin />
    <Branding className={styles.branding} />
  </div>

export default ResetPasswordPage;
