import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Modal, CardPreview, SectionDivider } from 'synapse-ui';
import get from 'lodash/get';
import { formatComments } from 'helpers/commentsSelector';
import QuestionContainer from './containers/QuestionContainer';
import QuestionsContext from 'context/QuestionsContext';
import CommentsContext from 'context/CommentsContext';
import SessionContext from 'context/SessionContext';
import { QUESTIONS_TYPES } from 'helpers/constants';
import { returnCommentsProps, returnCommentsActions } from './helpers';
import CheckboxQuestion from './components/CheckboxQuestion/CheckboxQuestion';
import CommentQuestion from './components/CommentQuestion/CommentQuestion';
import DateQuestion from './components/DateQuestion/DateQuestion';
import DropdownQuestion from './components/DropdownQuestion/DropdownQuestion';
import NumberQuestion from './components/NumberQuestion/NumberQuestion';
import RadioQuestion from './components/RadioQuestion/RadioQuestion';
import RangeQuestion from './components/RangeQuestion/RangeQuestion';
import UrlQuestion from './components/UrlQuestion/UrlQuestion';
import FileUploadQuestion from './components/FileUploadQuestion/FileUploadQuestion';
import styles from './RequestQuestions.module.scss';

const questionCards = {
  checkbox: CheckboxQuestion,
  customTextArea: CommentQuestion,
  date: DateQuestion,
  dropdown: DropdownQuestion,
  number: NumberQuestion,
  radio: RadioQuestion,
  range: RangeQuestion,
  url: UrlQuestion,
  file: FileUploadQuestion,
};

const RequestQuestions = ({ isDisabled }) => {
  const { currentUser, adminUsers } = useContext(SessionContext);
  const { questions, updateQuestionsArray } = useContext(QuestionsContext);
  const { comments, addComment, updateComment, removeComment } = useContext(CommentsContext);
  const [commentsMap, setCommentsMap] = useState({});
  const [commentIdToUpdate, setCommentIdToUpdate] = useState('');
  const [displayDeleteCommentModal, setDisplayDeleteCommentModal] = useState(false);
  const [deleteCommentModalData, setDeleteCommentModalData] = useState({});
  const location = useLocation();

  const scrollToQuestion = useCallback(() => {
    const query = new URLSearchParams(location.search);
    const questionId = query.get('questionId');
    if(questionId) {
      const element = document.getElementById(questionId);
      const commentEl = document.getElementById(questionId+'comment');
      if(element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
        setTimeout(() => commentEl.click(), 500);
      }
    }
  }, [location.search]);

  useEffect(() => {
    const list = formatComments(comments, currentUser, adminUsers);
    setCommentsMap(list);
    scrollToQuestion();
  }, [adminUsers, comments, currentUser, scrollToQuestion]);

  const handleEditComment = (commentId) => {
    setCommentIdToUpdate(commentId);
  };

  const handleDeleteComment = (questionId, commentId) => {
    setDisplayDeleteCommentModal(true);
    setDeleteCommentModalData({ questionId, commentId });
  };

  const closeDeleteCommentModal = () => {
    setDisplayDeleteCommentModal(false);
    setDeleteCommentModalData({});
  };

  const handleCancelComment = () => {
    if (commentIdToUpdate) {
      setCommentIdToUpdate(null);
    }
  };

  const handleOnComment = async (comment, question) => {
    if (!commentIdToUpdate) {
      await addComment(
        comment,
        question.id,
        question.data.label,
        currentUser,
      )
    } else {
      await updateComment(question.id, commentIdToUpdate, comment);
      setCommentIdToUpdate(null);
    }
  };

  let questionNumeration = 0;

  return (
    <div>
      <Modal
        open={displayDeleteCommentModal}
        onClose={closeDeleteCommentModal}
        type='dangerConfirmation'
        onApprove={removeComment}
        data={deleteCommentModalData}
        staticTexts={{
          title: intl.get('COLLABORATION.COMMENT.DELETE_MODAL.TITLE'),
          subTitle: intl.get('COLLABORATION.COMMENT.DELETE_MODAL.SUBTITLE'),
          cancelButton: intl.get('COLLABORATION.COMMENT.DELETE_MODAL.CANCEL_BUTTON'),
          approveButton: intl.get('COLLABORATION.COMMENT.DELETE_MODAL.APPROVE_BUTTON'),
        }}
      />

      <div className={styles.requiredLabel}>{intl.get('REQUEST.REQUIRED_LABEL')}</div>

      {questions.map((question, index) => {
        const Question = questionCards[question.type];
        if (question.type === QUESTIONS_TYPES.SECTION) {
          questionNumeration = 0;
          return (
            <div className={styles.divider}>
              <SectionDivider
                title={get(question, 'data.value', '')}
              />
            </div>
          );
        }
        questionNumeration += 1;
        return (
          <CardPreview
            commentActions={returnCommentsActions(
              handleEditComment,
              (commentId) => handleDeleteComment(question.id, commentId),
            )}
            commentIdToUpdate={commentIdToUpdate}
            commentsProps={returnCommentsProps(
              commentsMap[question.id],
              question.id,
              currentUser,
            )}
            handleCancelComment={handleCancelComment}
            handleOnComment={handleOnComment}
            index={`${questionNumeration}. `}
            questionData={question}
            key={question.id}
            showComment={isDisabled}
          >
            <QuestionContainer
              questionIndex={index}
              question={question}
              callback={updateQuestionsArray}
              render={({ onChange }) =>
                <Question
                  isDisabled={isDisabled}
                  onChange={onChange}
                  question={question}
                />
              }
            />
          </CardPreview>
        );
      })}
    </div>
  );
};

RequestQuestions.propTypes = {
  isDisabled: PropTypes.bool,
};

RequestQuestions.defaultProps = {
  isDisabled: false,
};

export default RequestQuestions;
