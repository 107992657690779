import React from 'react';
import LoadingSpinner from 'components/components/LoadingSpinner/LoadingSpinner';

const CallbackPage = () => {
  return (
    <LoadingSpinner/>
  )
}

export default CallbackPage;
