import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import intl from 'react-intl-universal';
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import './index.css';
import enUs from './locales/en-US.json';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import config from 'environment/Config';
import { initAxios } from 'helpers/axios';
import { initAxiosNotifications } from 'services/notifications';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Lato, Roboto, "Open Sans", Arial, sans-serif',
  },
  palette: {
    primary: {main: '#4381E2'},
  },
});

const locales = {
  'en-US': enUs,
};

intl.init({
  currentLocale: 'en-US', // TODO: determine locale here
  locales,
})
  .then(async() => {
    config.getConfig();
    await initAxios();
    initAxiosNotifications();
  })
  .then(() => {
    ReactDOM.render(
      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <App/>
        </ThemeProvider>
      </StylesProvider>,
      document.getElementById('root'),
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
