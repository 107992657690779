import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import styles from './SubmittedInfo.module.scss';

const SubmittedInfo = ({ updatedAt }) =>
  <div className={styles.submitted}>
    <div className={styles.label} data-testid='request__submitted'>
      {intl.get('REQUEST_ACTIONS.SUBMITTED')}
    </div>

    <div className={styles.updatedAt}>
      {new Date(updatedAt).toLocaleString('en-US', {dateStyle: 'medium', timeStyle: 'medium'})}
    </div>
  </div>;

SubmittedInfo.propTypes = {
  updatedAt: PropTypes.string.isRequired,
};

export default SubmittedInfo;
