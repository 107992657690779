import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import { ClickAwayListener } from '@material-ui/core';
import { Icon, TextField } from 'synapse-ui';
import RequestsContext from 'context/RequestsContext';
import styles from './RequestTitle.module.scss';

const RequestTitle = ({ request: {data, status}, updateRequestTitle, request }) => {
  const { replaceRequest } = useContext(RequestsContext);
  const [isEditingTitle, setIsEditingTitle] = useState(!data.requestTitle);
  const [isRequestTitleEmpty, setIsRequestTitleEmpty] = useState(true);
  const [requestTitle, setRequestTitle] = useState('');

  useEffect(() => {
    setRequestTitle(data.requestTitle || '')
    setIsEditingTitle(!data.requestTitle)
    setIsRequestTitleEmpty(!data.requestTitle)
  }, [request.id]);

  const isDraft = status === 'draft';

  const debounceUpdateRequestTitle = debounce(updateRequestTitle,500);
  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    request.data.requestTitle = newTitle;
    replaceRequest(request);
    setRequestTitle(newTitle)
    debounceUpdateRequestTitle(newTitle)

    if (newTitle && isRequestTitleEmpty) {
      setIsRequestTitleEmpty(false)
    }
    if (!newTitle) {
      setIsRequestTitleEmpty(true)
    }
  }

  const handleClickAway = () => {
    if (!isRequestTitleEmpty) {
      setIsEditingTitle(false)
    }
  };
  const editingTitle = () => setIsEditingTitle(true);

  const getDraftTitle = () =>
    isEditingTitle
      ?
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classnames(styles.textBoxcontainer,{ [styles.emptyTitle]: isRequestTitleEmpty })}>
          <div className={styles.titleLabel}>{intl.get('REQUEST.REQUEST_TITLE')}</div>
          <TextField
            className={styles.requestTitle}
            value={requestTitle}
            onChange={handleTitleChange}
            fullWidth
            placeholder={intl.get('REQUEST.TITLE_PLACEHOLDER')}
            InputProps={{ className: styles.input }}
            inputProps={{ 'data-testid': 'request__title' }}
          />
        </div>
      </ClickAwayListener>
      :
      <div className={styles.requestTitleLabel}>
        <div
          className={styles.title}
          onClick={editingTitle}
        >
          {requestTitle}
        </div>
        <Icon
          className={styles.pencilIcon}
          name='ion-md-create'
          onClick={editingTitle}
        />
      </div>

  const getNonDraftTtile = () =>
    <div className={styles.requestTitleLabel}>
      <div
        className={styles.title}
      >
        {data.requestTitle || data.title}
      </div>
    </div>

  return (
    <div className={styles.requestTitleContainer}>
      <div className={styles.formTitle}>
        {data.title}
      </div>
      { isDraft ? getDraftTitle() : getNonDraftTtile() }
    </div>
  )
}
 
RequestTitle.propTypes = {
  updateRequestTitle: PropTypes.func.isRequired,
  request: PropTypes.shape({
    id: PropTypes.string,
    accountId: PropTypes.string,
    data: PropTypes.shape({
      title: PropTypes.string,
      requestTitle: PropTypes.string,
    }),
    owner: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    status: PropTypes.string,
    type: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
};

export default RequestTitle;
