import React from 'react';

import LoadingSpinnerLogo from '../../../assets/images/LoadingSpinnerLogo.svg';
import styles from './LoadingSpinner.module.scss';

const LoadingSpinner = () => {
  return (
    LoadingSpinnerLogo && 
    <div>
      <div
        className={styles.loadingSpinnerLogo}
        style={{backgroundImage: `url(${LoadingSpinnerLogo})` }}
      />
      <p className={styles.welcomeToSynapse}>
          Welcome to Synapse...
      </p>
    </div>
  );
};

export default LoadingSpinner;
