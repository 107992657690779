import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button } from 'synapse-ui';
import styles from './DeleteModal.module.scss';

const DeleteModal = ({ onDelete, onClose }) =>
  <div className={styles.deleteModal}>
    <div className={styles.title}>{intl.get('DELETE_REQUEST.TITLE')}</div>
    <div className={styles.description}>{intl.get('DELETE_REQUEST.MESSAGE')}</div>
    <div className={styles.actionButtons}>
      <Button
        className={styles.cancelButton}
        onClick={onClose}
      >
        {intl.get('DELETE_REQUEST.CANCEL_BUTTON')}
      </Button>
      <Button
        className={styles.deleteButton}
        variant='contained'
        onClick={onDelete}
      >
        {intl.get('DELETE_REQUEST.DELETE_BUTTON')}
      </Button>
    </div>
  </div>;

DeleteModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteModal;
