import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import { putQuestion } from 'services/questionsService';

const QuestionContainer = ({
  questionIndex,
  question,
  callback,
  render,
}) => {
  const onChange = debounce((updatedValue, path) => {
    const updatedQuestion = cloneDeep(question);
    set(updatedQuestion, path, updatedValue);
    putQuestion(updatedQuestion);
    callback(updatedQuestion, questionIndex);
  }, 500);

  return render({
    onChange,
  });
};

QuestionContainer.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
};

export default QuestionContainer;
