import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Chip from '@material-ui/core/Chip';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { Icon } from 'synapse-ui';
import SideBarItem from '../SideBarItem/SideBarItem';

import styles from './SideBarExpansionPanel.module.scss';

const SideBarExpansionPanel = ({requests, heading, status}) =>
  <ExpansionPanel
    className={styles.expansionPanel}
    TransitionProps={{
      'data-testid': `sideBar__${status}-requests`,
    }}
  >
    <ExpansionPanelSummary
      className={styles.headerContainer}
      expandIcon={<Icon name='ion-ios-arrow-down'/>}
      IconButtonProps={{
        className: styles.expansionIcon,
        disableFocusRipple: true,
        disableRipple: true,
        'data-testid': `sideBar__${status}-requests-trigger`,
      }}
    >
      <Chip label={requests.length || 0} />
      <div className={styles.headerTitle}>{heading}</div>
    </ExpansionPanelSummary>
    <div
      className={styles.noFirstLine}
    >
      {
        requests.map((request) =>
          <SideBarItem
            key={request.id}
            id={request.id}
            status={request.status}
            updatedAt={new Date(request.updatedAt)}
            title={request.title}
          />,
        )
      }
    </div>
  </ExpansionPanel>

SideBarExpansionPanel.propTypes = {
  heading: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  requests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    accountId: PropTypes.string,
    data: PropTypes.shape({
      title: PropTypes.string,
    }),
    owner: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    status: PropTypes.string,
    type: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  })).isRequired,
};

export default SideBarExpansionPanel;
