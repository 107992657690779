import React, { useState, useEffect, useContext, useRef } from 'react';
import intl from 'react-intl-universal';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel } from 'synapse-ui';
import AccountSettingsContext from 'context/AccountSettingsContext';
import {
  REDIRECTS,
  AMPLIFY_ERROR_CODE,
} from 'helpers/constants';
import { logIn } from 'services/authenticationService';
import FormInput from 'components/components/FromInput/FormInput';
import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const history = useHistory();
  const location = useLocation();
  const emailRef = useRef();
  const passwordRef = useRef();
  const { accountId, enterpriseConnection } = useContext(AccountSettingsContext);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLogging, setIsLogging] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const onCheckboxChange = () => setKeepLoggedIn(!keepLoggedIn);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const isLogout = query.get('logout');

    if (enterpriseConnection.connectionId && !isLogout) {
      history.push('/ssoLoading');
    }

  },[history, location, enterpriseConnection]);

  const checkEmpty = () => {
    setIsSubmitDisabled(
      !emailRef.current.value
      ||
      !passwordRef.current.value);
    setEmailError('');
    setPasswordError('');
  };

  const validateEmail = (email) => {
    var emailReg = /^([\w-\+\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test(email);
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!checkEmpty()) {
      if (!validateEmail(emailRef.current.value)) {
        setEmailError(intl.get('LANDING_PAGE.INVALID_EMAIL'));
        setPasswordError('');
        return;
      }
      setIsLogging(true);
      const response = await logIn(emailRef.current.value, passwordRef.current.value);
      if (response.error) {
        switch (response.error.code) {
          case AMPLIFY_ERROR_CODE.USER_NOT_FOUND:
            setEmailError(intl.get('LANDING_PAGE.USER_NOT_FOUND_ERROR'));
            setPasswordError('');
            break;
          case AMPLIFY_ERROR_CODE.NOT_AUTHORIZED:
            setPasswordError(intl.get('LANDING_PAGE.EMAIL_PASSWORD_ERROR'));
            setEmailError('');
            break;
          default:
            setEmailError('');
            setPasswordError('');
        }
      }
      setIsLogging(false);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.loginForms}>
        <div className={styles.welcomeText}>
          {intl.get('LANDING_PAGE.WELCOME_TEXT')}
        </div>
        <div className={styles.loginTitle}>
          {intl.get('LANDING_PAGE.LOGIN_TITLE')}
        </div>
        <form onSubmit={handleLogin}>
          <FormInput
            placeholder={intl.get('LANDING_PAGE.EMAIL_PLACEHOLDER')}
            label={intl.get('LANDING_PAGE.EMAIL')}
            onChange={checkEmpty}
            inputRef={emailRef}
            hasError={emailError !== ''}
            helperText={emailError}
            testId='login-form__username'
          />
          <FormInput
            password
            label={intl.get('LANDING_PAGE.PASSWORD')}
            placeholder={intl.get('LANDING_PAGE.PASSWORD_PLACEHOLDER')}
            inputRef={passwordRef}
            onChange={checkEmpty}
            hasError={passwordError !== ''}
            helperText={passwordError}
            testId='login-form__password'
          />
          <Button
            className={styles.loginButton}
            disabled={isSubmitDisabled}
            color='primary'
            variant='contained'
            type='submit'
            isLoading={isLogging}
            onClick={handleLogin}
          >
            {intl.get('LANDING_PAGE.LOG_IN')}
          </Button>
        </form>

        <div className={styles.loginFooter}>
          <FormControlLabel
            className={styles.checkBox}
            control={
              <Checkbox
                checked={keepLoggedIn}
                color='primary'
                onChange={onCheckboxChange}
              />
            }
            label={intl.get('LANDING_PAGE.KEEP_LOGGED_IN')}
          />
          <Link
            to={`${REDIRECTS.FORGOT_PASSWORD}?accountId=${accountId}`}
            className={styles.forgotPassword}
          >
            {intl.get('LANDING_PAGE.FORGOT_PASSWORD')}
          </Link>
        </div>
      </div>
      <div className={styles.redirectMessage}>
        {intl.get('LANDING_PAGE.NO_ACCOUNT')}
        <Link
          to={`${REDIRECTS.REGISTER}?accountId=${accountId}`}
          className={styles.registerLink}
        >
          {intl.get('LANDING_PAGE.REGISTER_NOW')}
        </Link>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
