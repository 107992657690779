import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'synapse-ui';
import SubmitModal from '../SubmitModal';
import SubmitConfirmationModal from '../SubmitConfirmationModal/SubmitConfirmationModal';

const SubmitModalTrigger = ({ renderTrigger, onSubmit, isSubmitted, submittedRequest }) => {
  const [isModalOpen, setModalOpen ] = useState(false);
  return (
    <React.Fragment>
      { renderTrigger({ openModal: () => setModalOpen(true) }) }
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        Content={isSubmitted ? SubmitConfirmationModal : SubmitModal}
        contentData={isSubmitted ? 
          { submittedRequest } : { onSubmit }
        }
      />
    </React.Fragment>
  )
};

SubmitModalTrigger.propTypes = {
  renderTrigger: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  submittedRequest: PropTypes.object,
};

SubmitModalTrigger.defaultProps = {
  submittedRequest: {},
}

export default SubmitModalTrigger;
