import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'synapse-ui';
import DeleteModal from '../DeleteModal';

const DeleteModalTrigger = ({ renderTrigger, onDelete }) => {
  const [isModalOpen, setModalOpen ] = useState(false);
  return (
    <React.Fragment>
      { renderTrigger({ openModal: () => setModalOpen(true) }) }
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        Content={DeleteModal}
        contentData={{
          onDelete,
        }}
      />
    </React.Fragment>
  )
};

DeleteModalTrigger.propTypes = {
  renderTrigger: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteModalTrigger;
