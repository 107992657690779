import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { RangePreview as Range } from 'synapse-ui';

const RangeQuestion = ({ isDisabled, onChange, question }) =>
  <Range
    isDisabled={isDisabled}
    onChange={(newValue) => onChange(newValue, 'data.value.value')}
    minValue={parseInt(get(question, 'data.value.from', 0))}
    maxValue={parseInt(get(question, 'data.value.to', 0))}
    defaultValue={parseInt(get(question, 'data.value.value', 0))}
  />;

RangeQuestion.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        to: PropTypes.number.isRequired,
        from: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default RangeQuestion;
