import axios from 'axios';
import config from 'environment/Config';

let instance;

export const initAxiosNotifications = () => {
  instance = axios.create({
    baseURL: config.getValue('notifications.url'),
    headers: {
      common: {
        'Content-Type': 'application/json',
        'Authorization': `bearer ${config.getValue('notifications.apiKey')}`,
      },
    },
  });
};

export const triggerNotifications = body => {
  instance.post('/event', body);
}

export const fetchNotifications = async email => {
  if(email) {
    try{
      const { data } = await instance.get(`/alert/${email}`);
      return data;
    } catch (error) {
      return [];
    }
  }
}

export const updateNotification = async (notificationId, seen) => {
  return await instance.put(`alert/${notificationId}`, { seen });
};

export const markAllAsRead = async email => {
  return await instance.put(`alert/${email}/markAllAsSeen`);
};
