import React, {useMemo, useRef, useCallback, useContext} from 'react';
import intl from 'react-intl-universal';
import { TextField, Button } from 'synapse-ui';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { REDIRECTS, ACCOUNT_ID } from 'helpers/constants';
import ieSupportIcon from 'assets/images/ie-support-notice.svg';
import synapseLogo from 'assets/images/synapse-logo.svg';
import AccountSettingsContext from 'context/AccountSettingsContext';

import styles from './IeNotSupported.module.scss';

const IeNotSupported = () => {
  const copyInputRef = useRef(null);
  const { enterpriseConnection, accountId } = useContext(AccountSettingsContext);

  const url = useMemo(() => {
    const currentOrigin = get(window, 'location.origin');
    if (!isEmpty(enterpriseConnection)) {
      return `${currentOrigin}/${ACCOUNT_ID}?=${accountId}`;
    } else {
      return `${currentOrigin}${REDIRECTS.LOG_IN}`;
    }
  }, [enterpriseConnection, accountId]);

  const hasAccountId = useMemo(() => {
    if(url) {
      return url.includes(ACCOUNT_ID);
    }
  }, [url]);

  const onCopy = useCallback(() => {
    copyInputRef.current.select();
    document.execCommand('copy');
  }, []);

  return (
    <div className={styles.pageBackground}>
      <div className={styles.ieNotSupportedCard}>
        <div className={styles.logos}>
          <img
            className={styles.synapseLogo}
            src={synapseLogo}
            alt={intl.get('IE_NOT_SUPPORTED.SYNAPSE_ARIA')}
          />
          <img
            className={styles.ieNotSupportedIcon}
            src={ieSupportIcon}
            alt={intl.get('IE_NOT_SUPPORTED.IE_NOT_SUPPORTED_ARIA')}
          />
        </div>
        <h1 className={styles.title}>{intl.get('IE_NOT_SUPPORTED.BEST_EXPERIENCE')}</h1>
        <p className={styles.content}>{intl.getHTML('IE_NOT_SUPPORTED.USE_CHROME')}</p>
        <br/>
        <p className={styles.urlLabel}>
          {hasAccountId ?
            intl.getHTML('IE_NOT_SUPPORTED.URL_LABEL_SSO')
            :
            intl.getHTML('IE_NOT_SUPPORTED.URL_LABEL')
          }
        </p>
        <TextField
          variant='outlined'
          value={url}
          inputRef={copyInputRef}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Button
                className={styles.copyButton}
                disableElevation
                onClick={onCopy}
              >
                {intl.getHTML('IE_NOT_SUPPORTED.COPY')}
              </Button>
            ),
          }}
          margin='dense'
        />
      </div>
    </div>
  )
};

export default IeNotSupported;
