import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchRequestQuestions } from 'services/questionsService';
import QuestionsContext from 'context/QuestionsContext';

const QuestionsContainer = ({ children, loader, requestId }) => {
  const [questions, setQuestions] = useState();

  useEffect(() => {
    fetchRequestQuestions(requestId)
      .then(setQuestions);
  }, [requestId]);

  const updateQuestionsArray = (newQuestion, index) => {
    const updatedQuestionsArray = [...questions];
    updatedQuestionsArray[index] = newQuestion;
    setQuestions(updatedQuestionsArray);
  };

  if (questions) {
    return (
      <QuestionsContext.Provider
        value={{
          questions,
          updateQuestionsArray,
        }}
      >
        {children}
      </QuestionsContext.Provider>
    );
  }

  return loader();
};

QuestionsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  loader: PropTypes.func.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default QuestionsContainer;
