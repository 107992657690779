import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ErrorText.module.scss';

const ErrorText = ({ helperText, hasError }) => (
  <div className={classnames(styles.helperText, { [styles.error]: hasError })}>
    {helperText}
  </div>
);

ErrorText.propTypes = {
  helperText: PropTypes.string,
  hasError: PropTypes.bool,
};

ErrorText.defaultProps = {
  helperText: '',
  hasError: false,
};

export default ErrorText;
