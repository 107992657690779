import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TextField, Icon } from 'synapse-ui';
import styles from './FormInput.module.scss';

const FormInput = ({
  password,
  placeholder,
  label,
  className,
  hasError,
  helperText,
  isEmpty,
  testId,
  ...props
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(!password);

  const getEndAdornment = () => {
    if (password) {
      return (
        <Icon
          name={passwordVisibility ? 'ion-md-eye-off' : 'ion-md-eye'}
          className={styles.eyeIcon}
          onClick={() => setPasswordVisibility(!passwordVisibility)}
        />
      );
    }
  };

  const style = { height: '40px' };

  return (
    <div className={className}>
      <div className={styles.label}>{label}</div>
      <TextField
        type={passwordVisibility ? 'text' : 'password'}
        placeholder={placeholder}
        style={style}
        InputProps={{
          endAdornment: getEndAdornment(),
          className: classnames(styles.formInput, {
            [styles.isEmpty]: isEmpty,
            [styles.hasError]: hasError,
          }),
          'data-testid': testId,
        }}
        FormHelperTextProps={{
          className: classnames({ [styles.helperText]: hasError }),
        }}
        hasError={hasError}
        helperText={helperText}
        {...props}
      />
    </div>
  );
};

FormInput.propTypes = {
  password: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
  isEmpty: PropTypes.bool,
  testId: PropTypes.string,
};

FormInput.defaultProps = {
  password: false,
  placeholder: '',
  label: '',
  className: undefined,
  hasError: false,
  helperText: '',
  isEmpty: true,
  testId: '',
};

export default FormInput;
