import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import RequestsContainer from 'components/RequestsPage/containers/RequestsContainer';
import SideBarContainer from 'components/RequestsPage/containers/SideBarContainer';
import TopBar from './components/TopBar/TopBar';
import SideBar from './components/SideBar/SideBar';
import SideBarLoader from './components/SideBar/components/SideBarLoader/SideBarLoader';
import EmptyRequest from './components/EmptyRequest/EmptyRequest';
import RequestContainer from './containers/RequestContainer';
import Request from './components/Request/Request';
import NotificationsContainer from './containers/NotificationsContainer';
import styles from './RequestsPage.module.scss';

const RequestsPage = () => {
  const { path } = useRouteMatch();

  return (
    <RequestsContainer>
      <div className={styles.requestsLayout}>
        <SideBarContainer
          loader={() =>
            <SideBarLoader/>
          }
          render={({requests}) => 
            <SideBar requests={requests}/>
          }
        />
        <div className={styles.requestsContainer}>
          <NotificationsContainer
            loader={() =>
              <CircularProgress
                className={styles.loader}
                size={60}
                thickness={3}
              />
            }
          >
            <TopBar />
          </NotificationsContainer>
          <div className={styles.requestContent}>
            <Switch>
              <Route exact path={path}>
                <EmptyRequest />
              </Route>

              <Route path={`${path}/:requestId`}>
                <RequestContainer
                  loader={() =>
                    <CircularProgress
                      className={styles.loader}
                      size={60}
                      thickness={3}
                    />
                  }
                >
                  <Request />
                </RequestContainer>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </RequestsContainer>
  )
};

export default RequestsPage;
