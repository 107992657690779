import React, { useContext } from 'react';
import get from 'lodash/get';
import AccountSettingsContext from 'context/AccountSettingsContext';
import styles from './Logo.module.scss';

const Logo = () => {
  const { accountSettings } = useContext(AccountSettingsContext);
  const logo = get(accountSettings, 'branding.logo.url', '');

  return (
    logo &&
    <div
      className={styles.logo}
      style={{backgroundImage: `url(${logo})` }}
    />
  );
};

export default Logo;
