import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ForgotResetPasswordContext from 'context/ForgotPasswordContext';

const ForgotPasswordContainer = ({ children }) => {
  const [verifiedEmail, setVerifiedEmail] = useState();
  return (
    <ForgotResetPasswordContext.Provider
      value={{ verifiedEmail, setVerifiedEmail }}
    >
      {children}
    </ForgotResetPasswordContext.Provider>
  );
}

ForgotPasswordContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ForgotPasswordContainer;
