import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CommentPreview } from 'synapse-ui';

const CommentQuestion = ({ isDisabled, onChange, question }) =>
  <CommentPreview
    inputProps={{'data-testid': 'flexible-card__customTextArea'}}
    defaultValue={get(question, 'data.value', '')}
    isDisabled={isDisabled}
    onChange={(newValue) => onChange(newValue, 'data.value')}
  />;

CommentQuestion.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CommentQuestion;
