import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CheckBoxPreview as CheckBox } from 'synapse-ui';

const CheckboxQuestion = ({ isDisabled, onChange, question }) =>
  <CheckBox
    inputProps={{'data-testid': 'flexible-card__checkbox' }}
    defaultValue={get(question, 'data.value.items', [])}
    isDisabled={isDisabled}
    onChange={(newValue) => onChange(newValue, 'data.value.items')}
  />;

CheckboxQuestion.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({
          item: PropTypes.string.isRequired,
          checked: PropTypes.bool.isRequired,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default CheckboxQuestion;
