import React, { useEffect, useState } from 'react';
import Logo from 'components/components/Logo/Logo';
import { Branding } from 'synapse-ui';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';
import PromptText from './components/PromptText/PromptText';
import EmailForm from './components/EmailForm/EmailForm';
import styles from './ForgotPasswordPage.module.scss';
import { useLocation } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const location = useLocation();
  const [hasMessage, setHasMessage] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const hasMessage = query.get('hasMessage');
    if (hasMessage) {
      setHasMessage(true);
    }
  },[setHasMessage, location]);

  return (
    <ForgotPasswordContainer >
      <div className={styles.forgotPasswordPage}>
        <Logo />
        <PromptText hasMessage={hasMessage} />
        {!hasMessage && <EmailForm />}
        <Branding className={styles.branding} />
      </div>
    </ForgotPasswordContainer>
  );
};

export default ForgotPasswordPage;
