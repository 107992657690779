import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button, Icon } from 'synapse-ui';
import RequestContext from 'context/RequestContext';
import styles from './SubmitConfirmationModal.module.scss';

const SubmitConfirmationModal = ({ onClose, submittedRequest }) => {
  const { setRequest } = useContext(RequestContext);
  const handleClose = () => {
    onClose();
    setRequest(submittedRequest);
  };

  return (
    <div className={styles.confirmModal}>
      <div className={styles.congratsSection}>
        <div className={styles.confirmIcon}>
          <Icon name='ion-md-checkmark-circle-outline'/>
        </div>
        <div className={styles.congrats}>
          { intl.getHTML('SUBMIT_CONIFRMATION.CONGRATS') }
        </div>
      </div>
      <div className={styles.subMessage}>
        { intl.get('SUBMIT_CONIFRMATION.MESSAGE') }
      </div>
      <Button
        onClick={handleClose}
        color='primary'
        className={styles.closeButton}
        data-testid= 'success-modal__dismiss-button'
      >
        { intl.get('SUBMIT_CONIFRMATION.CLOSE_BUTTON') }
      </Button>
    </div>
  );
};

SubmitConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  submittedRequest: PropTypes.object.isRequired,
};

export default SubmitConfirmationModal;
