export const REDIRECTS = {
  LOG_IN: '/login',
  FORGOT_PASSWORD: '/forgotPassword',
  RESET_PASSWORD: '/resetPassword',
  REGISTER: '/signUp',
  REQUESTS: '/requests',
  IE_NOT_SUPPORTED: '/ieNotSupported',
}

export const AMPLIFY_ERROR_CODE = {
  USER_NOT_FOUND: 'UserNotFoundException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
}

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
};

export const PASSWORD_REQUIREMENTS = {
  MIN_LENGTH: 8,
  SPECIAL_CHARACTERS: /[!@#$%^&*]/,
  NUMBERS: /\d/,
  LOWERCASE_CHARACTERS: /[a-z]/,
  UPPERCASE_CHARACTERS: /[A-Z]/,
}

export const SNACKBAR = {
  AUTO_HIDDEN_TIME: 3000,
};

export const FILE_STACK_SOURCES = [
  'local_file_system',
  'imagesearch',
  'googledrive',
  'dropbox',
  'box',
  'onedrive',
  'onedriveforbusiness',
  'audio',
  'video',
]

export const FILE_STACK_ACCEPT = [
  'image/*',
  'video/mp4',
  'audio/mpeg',
  'mp3',
  'application/pdf',
  'text/plain',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  '.odt',
  '.odp',
  '.ai',
  '.psd',
]

export const QUESTIONS_TYPES = {
  FILE_UPLOADER: 'file',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  NUMBER: 'number',
  COMMENT: 'customTextArea',
  URL: 'url',
  RANGE: 'range',
  CHECKBOX: 'checkbox',
  RADIO_BUTTON: 'radio',
  SECTION: 'section',
};

export const NOTIFICATIONS_EVENTS_NAMES = {
  REQUEST_SUBMITTED: 'requestSubmitted',
  REQUEST_DELETED: 'requestDeleted',
  USER_LOGGED_IN: 'userLoggedIn',
  USER_REGISTERED: 'userRegistered',
  USER_ADDED_TRAINING_REQUEST_COMMENT: 'userAddedTrainingRequestComment',
  ALERT_SEEN: 'alertSeen',
  REQUEST_DRAFT_CREATED: 'requestDraftCreated',
  REQUESTER_SUBMITTED_REQUEST: 'requesterSubmittedRequest',
}

export const NOTIFICATIONS_ORIGIN = 'trainingRequest'

export const SYNAPSE_LOGO='https://marketing-image-production.s3.amazonaws.com/uploads/03c57460be947125252f59afe0f4359a6fa2e54c0cd0922f25d7c11b68a8ffc77280a30ccf788ff524393eddcde93d791dc83b17af53128ecaaea54062bdc5f4.jpg'

export const NOTIFICATION_TYPES = {
  REQUEST_STATUS_CHANGED: 'requestStatusChanged',
  MESSAGE: 'requestCommentCreated',
}

export const COMMENTS_ACTIONS = {
  EDIT: {
    TYPE: 'edit',
    ICON: 'ion-md-create',
  },
  REMOVE: {
    TYPE: 'remove',
    ICON: 'ion-md-trash',
  },
};

export const ACCOUNT_ID = 'accountId';
