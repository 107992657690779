import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './SideBarItem.module.scss';

const SideBarItem = ({ id, status, updatedAt, title }) =>
  <Link to={`/requests/${id}`} className={styles.sideBarItem}>
    <div className={styles.metaContainer}>
      <div className={classNames(styles.metaStatus, styles[status])} />
      <div className={styles.metaTime}>{updatedAt.toLocaleString('en-US', {dateStyle: 'medium', timeStyle: 'medium'})}</div>
    </div>
    <div className={styles.metaTitle}>{title}</div>
  </Link>

SideBarItem.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  updatedAt: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string.isRequired,
};

export default SideBarItem;
