import axios from 'axios';

export const fetchRequests = async user => {
  const { data } = await axios.get('requests', {
    params: {
      accountId: user['custom:accountId'],
      createdBy: user.email,
    },
  });
  return data;
};
