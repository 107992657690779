import React from 'react';
import intl from 'react-intl-universal';
import {
  Button,
  Icon,
} from 'synapse-ui';
import NewRequestModalTrigger from 'components/RequestsPage/components/NewRequestModal/ModalTrigger/ModalTrigger';
import styles from './EmptyRequest.module.scss';

const EmptyRequest = () =>
  <div className={styles.emptyRequest}>
    <img src='/icons/blank-state-training-request.svg' className={styles.emptyRequestIcon} alt='request icon'/>
    <NewRequestModalTrigger
      renderTrigger={({ openModal }) =>
        <Button
          color='primary'
          variant='contained'
          onClick={openModal}
        >
          <Icon
            className={styles.buttonIcon}
            name='ion-md-add'
          />
          {intl.get('HOME_PAGE.BUTTON_TEXT')}
        </Button>
      }
    />
  </div>;

export default EmptyRequest;
