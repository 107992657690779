import React from 'react';
import PropTypes from 'prop-types';
import styles from './WelcomeText.module.scss';

const WelcomeText = ({ welcomeText }) => (
  welcomeText &&
  <div className={styles.welcomeText}>
    {welcomeText}
  </div>
);

WelcomeText.propTypes = {
  welcomeText: PropTypes.string,
};

WelcomeText.defaultProps = {
  welcomeText: '',
};

export default WelcomeText;
