import PropTypes from 'prop-types';
import { PASSWORD_REQUIREMENTS } from 'helpers/constants';

const passwordValidator = async(password) => {
  const validations = {
    hasError: false,
    errors: {
      lengthError: false,
      lowercaseError: false,
      uppercaseError: false,
      numberError: false,
      specialCharacterError: false,
    },
  };
  const lowerCaseLetters = PASSWORD_REQUIREMENTS.LOWERCASE_CHARACTERS;
  const upperCaseLetters = PASSWORD_REQUIREMENTS.UPPERCASE_CHARACTERS;
  const numbers = PASSWORD_REQUIREMENTS.NUMBERS;
  const specialCharacters = PASSWORD_REQUIREMENTS.SPECIAL_CHARACTERS;

  if (password.length === 0) {
    return validations;
  }

  if (password.length < PASSWORD_REQUIREMENTS.MIN_LENGTH) {
    validations.errors.lengthError = true;
  };
  
  if(!lowerCaseLetters.test(password)) {
    validations.errors.lowercaseError = true;
  };

  if(!upperCaseLetters.test(password)) {
    validations.errors.uppercaseError = true;
  };

  if(!numbers.test(password)) {
    validations.errors.numberError = true;
  };

  if(!specialCharacters.test(password)) {
    validations.errors.specialCharacterError = true;
  };

  validations.hasError =
  validations.errors.lowercaseError
  || validations.errors.uppercaseError
  || validations.errors.specialCharacterError
  || validations.errors.lengthError
  || validations.errors.numberError;

  return validations;
};

passwordValidator.propTypes = {
  password: PropTypes.string.isRequired,
};

export default passwordValidator;
