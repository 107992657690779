import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import isUserAgentCompatible from 'helpers/isUserAgentCompatible';
import { getCurrentUser } from 'services/authenticationService';
import AccountSettingsContext from 'context/AccountSettingsContext';

const AccountIdSetterContainer = ({ children }) => {
  const { accountSettings, setAccountId, accountId, enterpriseConnection } = useContext(AccountSettingsContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const accountId = query.get('accountId');
    if(accountId){
      setAccountId(accountId);
    }
  }, [setAccountId, location]);

  useEffect(() => {
    const checkIfLoggedIn = async () => {
      const { error } = await getCurrentUser(false);
      if (!error && !location.pathname.startsWith('/requests')) {
        history.push('/requests');
      }
    };

    const checkIE = () => {
      if(!isUserAgentCompatible()) {
        history.push('/ieNotSupported');
      } else {
        checkIfLoggedIn();
      }
    };

    checkIE();
  }, [history, location.pathname]);

  return (
    <AccountSettingsContext.Provider
      value={{
        accountSettings,
        setAccountId,
        accountId,
        enterpriseConnection,
      }}
    >
      {children}
    </AccountSettingsContext.Provider>
  );
};

AccountIdSetterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default AccountIdSetterContainer;
