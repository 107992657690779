import Amplify, { Auth, Hub } from 'aws-amplify';
import config from 'environment/Config';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { addAuthorizationHeader, removeAuthorizationHeader } from 'helpers/axios';
import { triggerNotifications } from 'services/notifications';
import {
  REDIRECTS,
  NOTIFICATIONS_EVENTS_NAMES,
  NOTIFICATIONS_ORIGIN,
} from 'helpers/constants';

export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.getValue('aws.region'),
      userPoolId: config.getValue('authentication.userPoolId'),
      userPoolWebClientId: config.getValue('authentication.userPoolWebClient'),
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: config.getValue('authentication.domain'),
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin', 'phone'],
        redirectSignIn: config.getValue('authentication.redirectSignIn'),
        redirectSignOut: config.getValue('authentication.redirectSignOut'),
        responseType: 'code',
      },
    },
  });

  Hub.listen('auth', (response) => {
    if (response.payload.event === 'signIn') {
      addAccountIdToUser();
    }
  });
};

export const addAccountIdToUser = async () => {
  const ssoAccountId  = Cookies.get('ssoAccountId');
  Cookies.remove('ssoAccountId');

  const currentUser = await Auth.currentAuthenticatedUser();

  if (isEmpty(currentUser.attributes['custom:accountId'])) {
    triggerNotifications({
      eventName: NOTIFICATIONS_EVENTS_NAMES.USER_REGISTERED,
      email: currentUser.attributes.email,
      data: {
        firstName: currentUser.attributes.given_name,
        lastName: currentUser.attributes.family_name,
      },
      accountId: ssoAccountId,
      origin: NOTIFICATIONS_ORIGIN,
    });

    await Auth.updateUserAttributes(currentUser, {
      'custom:accountId': ssoAccountId,
    });
  }

  afterLogin(currentUser);
};

export const logIn = async (email, password) => {
  const response = { error: false, user: {} };
  try {
    const user = await Auth.signIn(email, password);
    response.user = user;
  } catch (error) {
    response.error = error;
  }
  return response;
};

const afterLogin = (user) => {
  const bearerToken = get(user, 'signInUserSession.idToken.jwtToken');
  if (bearerToken) {
    addAuthorizationHeader(bearerToken);
  }

  triggerNotifications({
    eventName: NOTIFICATIONS_EVENTS_NAMES.USER_LOGGED_IN,
    email: get(user, 'attributes.email'),
    accountId: get(user, 'attributes[custom:accountId]'),
    origin: NOTIFICATIONS_ORIGIN,
  });

  const redirectUrlCallback = Cookies.get('redirectUrlCallback');
  Cookies.remove('redirectUrlCallback');

  if (redirectUrlCallback) {
    window.location.replace(redirectUrlCallback);
  } else {
    window.location.replace(REDIRECTS.REQUESTS);
  }
};

export const verifyEmail = async email => {
  const response = { error: false, data: {} };
  try {
    const data = await Auth.forgotPassword(email)
    response.data = data;
  } catch (error) {
    response.error = error;
  }
  return response;
};

export const resetPassword = async (username, code, newPassword) => {
  const response = { error: false, data: {} };
  try {
    const data = await Auth.forgotPasswordSubmit(username, code, newPassword)
    response.data = data;
  } catch (error) {
    response.error = error;
  }
  return response;
};

export const logOut = async () => {
  const response = await Auth.signOut();
  removeAuthorizationHeader();
  return response;
};

export const getCurrentUser = async (bypassCache = true) => {
  const response = { error: false, user: {} };
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache });
    response.user = user;
  } catch (error) {
    response.error = error;
  }
  return response;
};

export const signUp = async (user, accountId) => {
  try {
    return await Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        given_name: user.firstName,
        family_name: user.lastName,
        'custom:accountId': accountId,
      },
    });
  } catch (error) {
    return {error: error};
  }
}

export const confirmUser = async (username, code) => {
  try {
    return await Auth.confirmSignUp(username, code);
  } catch (error) {
    return {error: true, message: `Error: ${error.message}`};
  }
};
