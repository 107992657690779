import React, { useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button, Select, FormControl } from 'synapse-ui';
import styles from './NewRequestModal.module.scss';

const NewRequestModal = ({ forms, createRequest, onClose }) => {
  const [selectedForm, setSelectedForm] = useState();

  const handleFormSubmit = () => {
    createRequest(selectedForm);
    onClose();
  };

  return (
    <div className={styles.requestModal} data-testid='form-modal'>
      <div className={styles.title}>{ intl.get('NEW_REQUEST_MODAL.TITLE') }</div>
      <div className={styles.description}>{ intl.get('NEW_REQUEST_MODAL.DESCRIPTION') }</div>
      <FormControl className={styles.formPicker}>
        <div className={styles.formLabel}>
          { intl.get('NEW_REQUEST_MODAL.FORMS_SELECT_LABEL') }
        </div>
        <Select
          options={forms}
          emptyMessage={intl.get('NEW_REQUEST_MODAL.EMPTY_SELECT')}
          afterChange={setSelectedForm}
          SelectDisplayProps={{
            'data-testid': 'form-modal__select-form',
          }}
          MenuProps={{
            MenuListProps: {
              'data-testid': 'form-modal__forms-list',
            },
          }}
        />
      </FormControl>
      <div className={styles.actionButtons}>
        <Button
          className={styles.cancelButton}
          onClick={onClose}
        >
          { intl.get('NEW_REQUEST_MODAL.CANCEL_BUTTON') }
        </Button>
        <Button
          className={styles.submitButton}
          variant='contained'
          disabled={!selectedForm}
          onClick={handleFormSubmit}
          data-testid='form-modal__submit-button'
        >
          { intl.get('NEW_REQUEST_MODAL.SUBMIT_BUTTON') }
        </Button>
      </div>
    </div>
  )
};

NewRequestModal.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.node.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  })).isRequired,
  createRequest: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewRequestModal;
