import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import { UrlPreview as Url } from 'synapse-ui';

const UrlQuestion = ({ isDisabled, onChange, question }) =>
  <Url
    inputProps={{'data-testid': 'flexible-card__url'}}
    placeholder={intl.get('URL_PLACEHOLDER')}
    defaultValue={get(question, 'data.value', '')}
    isDisabled={isDisabled}
    onChange={(newValue) => onChange(newValue, 'data.value')}
  />;

UrlQuestion.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default UrlQuestion;
