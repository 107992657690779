import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Icon,
} from 'synapse-ui';

import styles from './UserInfoTrigger.module.scss';

const UserInfoTrigger = ({
  isOpen,
  open,
  userName,
}) =>
  <Button
    onClick={open}
    className={styles.triggerButton}
  >
    <Icon
      name='ion-md-contact'
      className={styles.contactIcon}
    />

    <span>{userName}</span>

    <Icon
      name={isOpen ? 'ion-md-arrow-dropup' : 'ion-md-arrow-dropdown'}
      className={styles.arrowIcon}
    />
  </Button>;

UserInfoTrigger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};

export default UserInfoTrigger;
