import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import classnames from 'classnames';

import { Button } from 'synapse-ui';

import styles from './UserInfoPopup.module.scss';

const UserInfoPopup = ({
  email,
  onLogout,
}) => {
  return (
    <div className={styles.userInfoPopup}>
      <div className={styles.popupRow}>
        <span>{intl.get('USER_INFO.EMAIL_TAG')}</span>

        <span className={styles.textDescription}>{email}</span>
      </div>

      <div className={classnames(styles.popupRow, styles.popupActions)}>
        <Button
          className={styles.logoutButton}
          onClick={onLogout}
        >
          {intl.get('USER_INFO.LOGOUT_BUTTON')}
        </Button>
      </div>
    </div>
  );
};

UserInfoPopup.propTypes = {
  email: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default UserInfoPopup;
