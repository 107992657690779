import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { isEmpty } from 'lodash';
import { AppBar, Toolbar } from '@material-ui/core';
import { Notifications } from 'synapse-ui';
import { getNotificationsList, checkNewAlerts } from 'helpers/notificationsSelector';
import { REDIRECTS } from 'helpers/constants';
import NotificationsContext from 'context/NotificationsContext';
import SessionContext from 'context/SessionContext';
import UserInfo from './components/UserInfo/UserInfo';
import styles from './TopBar.module.scss';

const TopBar = () => {
  const {
    notifications,
    onUpdateNotification,
    onMarkAllAsRead,
  } = useContext(NotificationsContext);
  const { currentUser } = useContext(SessionContext);
  const [notificationsList, setNotificationsList] = useState([]);
  const [hasNewAlerts, setHasNewAlerts] = useState(false);
  const history = useHistory();
  
  const onNotificationClik = async(questionId, requestId, alertId) => {
    questionId ?
      history.push(`${REDIRECTS.REQUESTS}/${requestId}?questionId=${questionId}`)
      :
      history.push(`${REDIRECTS.REQUESTS}/${requestId || ''}`);
    onUpdateNotification(alertId, true);
  };

  const isNotificationsEmpty = () => {
    return isEmpty(notifications)
  };

  useEffect(() => {
    const list = getNotificationsList(notifications);
    const areTherUnseenAlerts = checkNewAlerts(notifications);
    setNotificationsList(list);
    setHasNewAlerts(areTherUnseenAlerts);
  }, [notifications]);

  const staticTexts = {
    markAsRead: intl.get('COLLABORATION.NOTIFICATIONS.MARK_AS_READ'),
    markAsUnread: intl.get('COLLABORATION.NOTIFICATIONS.MARK_AS_UNREAD'),
    notificationsTitle: intl.get('COLLABORATION.NOTIFICATIONS.TITLE'),
    markAllAsRead: intl.get('COLLABORATION.NOTIFICATIONS.MARK_ALL_AS_READ'),
    emptyStateMessage: intl.get('COLLABORATION.NOTIFICATIONS.EMPTY_MESSAGE'),
  };

  const handleClickMarkAsRead = (alertId, newStatus) => onUpdateNotification(alertId, newStatus);

  const handleMarkAllAsRead = () => {
    onMarkAllAsRead(currentUser.attributes.email);
  }

  return(
    <AppBar position='sticky' color='inherit' elevation={1}>
      <Toolbar variant='dense'>
        <div className={styles.topBar}>
          <div className={styles.notificationSpacer}>
            <Notifications
              notifications={notificationsList}
              notificationItemAction={onNotificationClik}
              notificationData={{
                changedStatus: {
                  requestStatusStaticText: intl.get('COLLABORATION.NOTIFICATIONS.CHANGED_REQUEST_STATUS'),
                  messageStaticText: intl.get('COLLABORATION.NOTIFICATIONS.MESSAGE_LEFT'),
                  status: {
                    rejected: intl.get('COLLABORATION.NOTIFICATIONS.CLOSED'),
                    approved: intl.get('COLLABORATION.NOTIFICATIONS.APPROVED'),
                    submitted: intl.get('COLLABORATION.NOTIFICATIONS.SUBMITTED'),
                    draft: intl.get('COLLABORATION.NOTIFICATIONS.DRAFT'),
                  },
                  submittedText: {
                    staticText: intl.get('COLLABORATION.NOTIFICATIONS.SUBMITTED_STATIC_TEXT'),
                    note: intl.get('COLLABORATION.NOTIFICATIONS.SUBMITTED_NOTE'),
                  },
                },
                commentNotification: {
                  replyStaticText: intl.get('COLLABORATION.NOTIFICATIONS.REPLIED'),
                },
                requestSubmitted: {
                  staticText: intl.get('COLLABORATION.NOTIFICATIONS.REQUEST_SUBMITTED_STATIC_TEXT'),
                },
              }}
              isNotificationsEmpty={isNotificationsEmpty()}
              staticTexts={staticTexts}
              onClickMarkAsRead={handleClickMarkAsRead}
              hasNewAlerts={hasNewAlerts}
              onClickMarkAllAsRead={handleMarkAllAsRead}
            />
          </div>
          <UserInfo/>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
