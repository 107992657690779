import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { Icon } from 'synapse-ui';
import { REDIRECTS } from 'helpers/constants';
import AccountSettingsContext from 'context/AccountSettingsContext';
import styles from './GoToLogin.module.scss';

const GoToLogin = ({ promptText }) => {
  const { accountId } = useContext(AccountSettingsContext);

  return (
    <Link
      to={`${REDIRECTS.LOG_IN}?accountId=${accountId}`}
      className={styles.backToLogin}
    >
      <Icon className={styles.arrow} name='ion-md-arrow-back' />
      {promptText ? promptText : intl.get('FORGOT_PASSWORD_PAGE.BACK_TO_LOGIN')}
    </Link>
  )
};

GoToLogin.propTypes = {
  promptText: PropTypes.string,
};

GoToLogin.defaultProps = {
  promptText: '',
};

export default GoToLogin;
