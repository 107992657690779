import axios from 'axios';
import config from 'environment/Config';

export const fetchAccountSettings = async (accountId) => {
  if(accountId) {
    try{
      const { data } = await axios({
        url: `v1/config/account/${accountId}`,
        baseURL: config.getValue('synapseBackendURL'),
      });
      return data;
    } catch (error) {
      return {error: true};
    }
  }
};

export const fetAccountAdminUsers = async (accountId) => {
  try {
    const { data } = await axios({
      url: `v1/account/${accountId}/adminEmails`,
      baseURL: config.getValue('synapseBackendURL'),
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const fetchAccount = async (accountId) => {
  try {
    const { data } = await axios({
      url: `v1/account/user/${accountId}`,
      baseURL: config.getValue('synapseBackendURL'),
    });
    return data;
  } catch (error) {
    return [];
  }
};
