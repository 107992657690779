import axios from 'axios';

export const fetchRequest = async (requestId) => {
  const { data } = await axios.get(`requests/${requestId}`);
  return data;
}

export const createRequest = async (formId, user) => {
  const { data } = await axios.post(`forms/${formId}/requests`, {
    'createdBy': user.email.toLowerCase(),
    'creatorName': `${user.given_name} ${user.family_name}`,
    'accountId': user['custom:accountId'],
  });
  return data;
};

export const updateRequest = async (requestId, update) => {
  const { data } = await axios.put(`requests/${requestId}`, update);
  return data;
}

export const deleteRequest = async requestId => {
  const { data } = await axios.delete(`requests/${requestId}`);
  return data;
}
