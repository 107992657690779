import React, { useContext } from 'react';
import { Dropdown } from 'synapse-ui';

import { logOut } from 'services/authenticationService';

import SessionContext from 'context/SessionContext';
import UserInfoTrigger from './components/UserInfoTrigger/UserInfoTrigger';
import UserInfoPopup from './components/UserInfoPopup/UserInfoPopup';

const UserInfo = () => {
  const { currentUser, changeCurrentUser } = useContext(SessionContext);

  const handleLogout = () => {
    logOut();
    changeCurrentUser(undefined);
  };

  return (
    <Dropdown
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      renderTrigger={({open, isOpen}) =>
        <UserInfoTrigger
          isOpen={isOpen}
          open={open}
          userName={`${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`}
        />
      }
      renderContent={() =>
        <UserInfoPopup
          email={currentUser.attributes.email}
          onLogout={handleLogout}
        />
      }
    />
  );
};

export default UserInfo;
