import { get, cloneDeep } from 'lodash';
import moment from 'moment';
import { COMMENTS_ACTIONS } from 'helpers/constants';

export const formatComments = (data, currentUser, adminUsers) => {
  const newData = cloneDeep(data);
  for (const questionId in newData) {
    newData[questionId] = newData[questionId].map(
      comment => formatComment(comment, currentUser, adminUsers),
    );
  }
  return newData;
};

const isUserAdmin = (newComment, adminUsers) => {
  return adminUsers.includes(get(newComment, 'author.email', ''));
};

const getAllowedActions = (comment, currentUser) => {
  const commentAuthor = get(comment, 'author.email', '').toLowerCase();
  if (commentAuthor === currentUser.attributes.email) {
    return [
      COMMENTS_ACTIONS.EDIT.TYPE,
      COMMENTS_ACTIONS.REMOVE.TYPE,
    ];
  }
  return [];
};

const formatComment = (comment, currentUser, adminUsers) => {
  comment.createdAt = moment(comment.createdAt).fromNow();
  comment.userAdmin = isUserAdmin(comment, adminUsers);
  comment.allowedActions = getAllowedActions(comment, currentUser);
  return comment;
};
