import React, { useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import AccountSettingsContext from 'context/AccountSettingsContext';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoadingSpinner from 'components/components/LoadingSpinner/LoadingSpinner';

const SSORedirectPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { accountId, enterpriseConnection } = useContext(AccountSettingsContext);

  useEffect(() => {
    if (accountId && !isEmpty(enterpriseConnection.connectionId)) {
      Cookies.set('ssoAccountId', accountId);
      window.location.replace(enterpriseConnection.connectionId);
      return;
    }

    history.push('/login');
  },[history, location, accountId, enterpriseConnection]);

  return (
    <LoadingSpinner/>
  )
}

export default SSORedirectPage;
