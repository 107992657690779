import React, { useState, useContext, useRef } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button } from 'synapse-ui';
import { AMPLIFY_ERROR_CODE, REDIRECTS } from 'helpers/constants';
import ForgotResetPasswordContext from 'context/ForgotPasswordContext';
import { verifyEmail } from 'services/authenticationService';
import FormInput from 'components/components/FromInput/FormInput';
import GoToLogin from 'components/components/GoToLogin/GoToLogin';
import styles from './EmailForm.module.scss';

const EmailForm = () => {
  const history = useHistory();
  const emailRef = useRef();
  const { setVerifiedEmail } = useContext(ForgotResetPasswordContext);
  const [emailIsEmpty, setEmailIsEmpty] = useState(true);
  const [error, setError] = useState('');
  const handleEmailChange = event => {
    const isEmailEmpty = isEmpty(event.target.value);
    if (isEmailEmpty !== emailIsEmpty) {
      setEmailIsEmpty(isEmailEmpty);
    }
    if (!isEmpty(error)) {
      setError('');
    }
  };

  const validateEmail = (email) => {
    const emailReg = /^([\w-\+\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test(email);
  }

  //TODO change this to sending reset email
  const handlePasswordReset = async (event) => {
    event.preventDefault();
    if (!validateEmail(emailRef.current.value)) {
      setError(intl.get('FORGOT_PASSWORD_PAGE.INVALID_EMAIL'));
    } else {
      const response = await verifyEmail(emailRef.current.value);
      if (response.error.code === AMPLIFY_ERROR_CODE.USER_NOT_FOUND) {
        setError(intl.get('FORGOT_PASSWORD_PAGE.EMAIL_ERROR'));
      } else {
        setError('');
        setVerifiedEmail(emailRef.current.value);
        history.push(`${REDIRECTS.FORGOT_PASSWORD}?hasMessage=true`);
      }
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handlePasswordReset}>
        <FormInput
          label={intl.get('FORGOT_PASSWORD_PAGE.EMAIL')}
          placeholder={intl.get('FORGOT_PASSWORD_PAGE.EMAIL_PLACEHOLDER')}
          isEmpty={emailIsEmpty}
          inputRef={emailRef}
          onChange={handleEmailChange}
          hasError={error !== ''}
          helperText={error}
        />
        <Button
          type='submit'
          className={styles.resetButton}
          color='primary'
          variant='contained'
          onClick={handlePasswordReset}
          disabled={emailIsEmpty}
        >
          {intl.get('FORGOT_PASSWORD_PAGE.RESET_MY_PASSWORD')}
        </Button>
      </form>
      <GoToLogin />
    </React.Fragment>
  );
};

export default EmailForm;
