import axios from 'axios';

export const fethAccountForms = async accountId => {
  const { data } = await axios.get('forms', {
    params: { accountId, published: true },
  });
  const forms = data.map(item => ({
    display: item.data.title,
    value: item.id,
  }));
  return forms;
};

