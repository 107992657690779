import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './SideBarLoader.module.scss';

const SideBarLoader = () =>
  <div className={styles.sideBarLoader}>
    <CircularProgress
      size={60}
      thickness={3}
    />
  </div>;

export default SideBarLoader;
