import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import moment from 'moment';
import {
  Button,
  Icon,
  Tooltip,
} from 'synapse-ui';
import {
  NOTIFICATIONS_EVENTS_NAMES,
  NOTIFICATIONS_ORIGIN,
  SYNAPSE_LOGO,
} from 'helpers/constants';
import { areRequiredQuestionsFilled } from 'helpers/questionsValidator';
import { updateRequest } from 'services/requestService';
import RequestsContext from 'context/RequestsContext';
import QuestionsContext from 'context/QuestionsContext';
import SessionContext from 'context/SessionContext';
import AccountSettingsContext from 'context/AccountSettingsContext';
import SubmitModalTrigger from '../../../SubmitModal/SubmitModalTrigger/SubmitModalTrigger';
import { triggerNotifications } from 'services/notifications';
import styles from './SubmitButton.module.scss';

const SubmitButton = ({ className, request }) => {
  const { replaceRequest } = useContext(RequestsContext);
  const { questions } = useContext(QuestionsContext);
  const { currentUser } = useContext(SessionContext);
  const { accountSettings } = useContext(AccountSettingsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submittedRequest, setSubmittedRequest] = useState()
  const [canSubmitRequest, setCanSubmitRequest] = useState(false);

  useEffect(() => {
    const canSubmit = areRequiredQuestionsFilled(questions, request);
    setCanSubmitRequest(canSubmit);
  }, [questions, request]);

  const onSubmit = async() => {
    setIsLoading(true);
    const submittedRequest = await updateRequest(request.id, { status: 'submitted' });
    setIsLoading(false);
    replaceRequest(submittedRequest);
    setSubmitted(true)
    setSubmittedRequest(submittedRequest);

    const requestorEmail = get(submittedRequest, 'createdBy', '').toLowerCase();
    const creatorName = `${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`;
    const accountId = get(submittedRequest, 'accountId', '');
    const requestId = get(submittedRequest, 'id', '');
    const formTitle = get(submittedRequest, 'data.title', '');
    const requestTitle = get(submittedRequest, 'data.requestTitle', formTitle);
    const ownerEmail = get(submittedRequest, 'owner.email', currentUser.attributes.email).toLowerCase();
    const ownerName = get(submittedRequest, 'owner.name', '');
    const ownerType = get(submittedRequest, 'owner.type', '');

    triggerNotifications({
      eventName: NOTIFICATIONS_EVENTS_NAMES.REQUEST_SUBMITTED,
      email: ownerEmail,
      accountId,
      origin: NOTIFICATIONS_ORIGIN,
      data: {
        requestId,
        formTitle: formTitle,
        title: requestTitle,
        createdAt: moment(new Date()).format('MMM D, YYYY - h:mm A'),
        requestorInitials: `${get(currentUser, 'attributes.given_name[0]')}${get(currentUser, 'attributes.family_name[0]')}`,
        ownerType,
        ownerName,
        status: submittedRequest.status,
        statusString: intl.get('REQUEST.SUBMITTED'),
        hasMessage: false,
        subject: intl.get('REQUEST.USER_HAS_SUBMITTED_REQUEST', {user: creatorName}),
        requestorEmail,
        author: creatorName,
      },
      emailerData: {
        subject: intl.get('REQUEST.YOUR_REQUEST_HAS_BEEN_SUBMITTED'),
        companyLogo: get(accountSettings, 'branding.logo.url', '') || SYNAPSE_LOGO,
        submittedAt: moment(new Date()).format('ddd D MMM, h:mm A'),
      },
    });
  };

  return (
    <SubmitModalTrigger
      renderTrigger={({ openModal }) =>
        <Tooltip
          title={canSubmitRequest ? '' : intl.get('REQUEST.DISABLE_TOOLTIP')}
          placement='left-start'
          classes={{ tooltip: styles.toolTip }}
        >
          <div>
            <Button
              className={className}
              color='primary'
              isLoading={isLoading}
              onClick={openModal}
              variant='contained'
              disabled={!canSubmitRequest}
              data-testid='request__send-button'
            >
              {intl.get('REQUEST_ACTIONS.SUBMIT')}
              <Icon className={styles.sendIcon} name='ion-md-send' />
            </Button>
          </div>
        </Tooltip>
      }
      onSubmit={onSubmit}
      isSubmitted={submitted}
      submittedRequest={submittedRequest}
    />
  );
};

SubmitButton.propTypes = {
  className: PropTypes.string,
  request: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default SubmitButton;
