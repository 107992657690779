import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SubmitButton from './components/SubmitButton/SubmitButton';
import DeleteButton from './components/DeleteButton/DeleteButton';
import SubmittedInfo from './components/SubmittedInfo/SubmittedInfo';

import styles from './RequestActions.module.scss';

const RequestActions = ({ request }) => {
  const isDraft = request.status === 'draft';

  return (
    <div className={styles.requestActions}>
      {isDraft &&
        <Fragment>
          <SubmitButton
            className={styles.submitButton}
            request={request}
          />

          <DeleteButton request={request} />
        </Fragment>
      }

      {!isDraft &&
        <SubmittedInfo updatedAt={request.updatedAt} />
      }
    </div>
  );
};

RequestActions.propTypes = {
  request: PropTypes.shape({
    status: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default RequestActions;
