import React, { Fragment, useContext } from 'react';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import { CircularProgress } from '@material-ui/core';
import { Card } from 'synapse-ui';
import RequestMessage from './components/RequestMessage/RequestMessage';
import QuestionsContainer from './containers/QuestionsContainer';
import CommentsContainer from './containers/CommentsContainer';
import RequestContext from 'context/RequestContext';
import RequestQuestions from './components/RequestQuestions/RequestQuestions';
import RequestTitle from './components/RequestTitle/RequestTitle';
import RequestActions from './components/RequestActions/RequestActions';

import styles from './Request.module.scss';

const Request = () => {
  const { request, updateRequestTitle } = useContext(RequestContext);

  return (
    <Fragment>
      {
        request.status !== 'draft' &&
        <div className={styles.statusWrapper}>
          <div className={classnames(styles.requestStatus, styles[request.status])}>
            {intl.getHTML(`REQUEST.STATUSES.${request.status.toUpperCase()}`)}
          </div>
        </div>
      }
      {
        request.data.message && <RequestMessage message={request.data.message}/>
      }
      <div className={classnames(styles.request, { [styles.hasStatus]: request.status !== 'draft' && !request.data.message })}>
        <QuestionsContainer
          loader={() =>
            <CircularProgress
              className={styles.loader}
              size={60}
              thickness={3}
            />
          }
          requestId={request.id}
        >
          <Card className={classnames(styles.requestContent, styles[request.status])}>
            <RequestTitle
              updateRequestTitle={updateRequestTitle}
              request={request}
            />
            <CommentsContainer
              loader={() =>
                <CircularProgress
                  className={styles.loader}
                  size={60}
                  thickness={3}
                />
              }
              requestId={request.id}
              requestTitle={request.data.requestTitle || request.data.title}
              requestOwner={request.owner}
            >
              <RequestQuestions isDisabled={request.status !== 'draft'} />
            </CommentsContainer>
          </Card>
          <RequestActions
            request={request}
          />
        </QuestionsContainer>
      </div>
    </Fragment>
  );
};

export default Request;
