export const initPendo = (userEmail, userName = '', accountId, accountName) => {
  if (!window.pendo) {
    return;
  }
  const account = {
    id: accountId,
  };
  if (accountName) {
    account['name'] = accountName;
  }
  window.pendo.initialize({
    visitor: {
      id: userEmail,
      email: userEmail,
      'full_name': userName,
    },
    account,
  });
};
