import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { RadioPreview as Radio } from 'synapse-ui';

const RadioQuestion = ({ isDisabled, onChange, question }) =>
  <Radio
    inputProps={{'data-testid': 'flexible-card__radio'}}
    options={get(question, 'data.value.options', [])}
    defaultSelected={question.data.value.value}
    isDisabled={isDisabled}
    onChangeSelected={(newValue) => onChange(newValue, 'data.value.value')}
  />;

RadioQuestion.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })).isRequired,
        value: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default RadioQuestion;
