import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import has from 'lodash/has';
import { FileUploader } from 'synapse-ui';
import { getSignedUrl } from 'services/cloudfrontService';
import { FILE_STACK_SOURCES, FILE_STACK_ACCEPT } from 'helpers/constants';
import config from 'environment/Config';

const FileUploadQuestion = ({ isDisabled, onChange, question }) => {
  const [signedLink, setSignedLink] = useState(null);
  const FILES_CONFIG = config.getValue('files');

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (has(question, 'data.value.value.key')) {
        const key = question.data.value.value.key;
        const defaultCloudfrontUrl = config.getValue('cloudfrontUrl');
        const cloudfrontUrls = config.getValue('cloudfrontUrls');
        const container = get(question, 'data.value.value.container', '');
        const selectedCloudfrontUrl = cloudfrontUrls[container] || defaultCloudfrontUrl;
        const signedUrl = await getSignedUrl(`${selectedCloudfrontUrl}/${encodeURIComponent(key).replaceAll('%20', '+')}`);
        setSignedLink(signedUrl);
      }
    }
    fetchSignedUrl();
  }, [question]);

  return <FileUploader
    fileStackConfig={{
      location: FILES_CONFIG.location,
      container: FILES_CONFIG.bucketName,
      region: config.getValue('aws.region'),
      fileStackSources: FILE_STACK_SOURCES,
      fileStackAccept: FILE_STACK_ACCEPT,
      fileStackPolicy: FILES_CONFIG.stackPolicy,
      fileStackSignature: FILES_CONFIG.stackSignature,
      fileStackApiKey: FILES_CONFIG.stackApiKey,
      fileStackCname: FILES_CONFIG.stackCName,
    }}
    cloudfrontUrl={signedLink}
    buttonEmptyText={intl.get('FILE_STACK.EMPTY_BUTTON')}
    buttonText={intl.get('FILE_STACK.BUTTON_TEXT')}
    defaultValue={get(question, 'data.value.value', {})}
    isDisabled={isDisabled}
    onChange={(newValue) => onChange(newValue, 'data.value.value')}
    canReplaceFile={!isDisabled}
    maxFileSizeMessage={intl.get('FILE_STACK.MAZ_FILE_SIZE_MESSAGE', {amount: 100})}
  />
};

FileUploadQuestion.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    file: PropTypes.object,
    fileName: PropTypes.string,
    label: PropTypes.string,
    data: PropTypes.shape({
      value: PropTypes.shape({
        value: PropTypes.object,
      }),
    }),
  }).isRequired,
};

export default FileUploadQuestion;
