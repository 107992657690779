import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import {
  NOTIFICATIONS_EVENTS_NAMES,
  NOTIFICATIONS_ORIGIN,
  SYNAPSE_LOGO,
} from 'helpers/constants';
import { triggerNotifications } from 'services/notifications';
import { fetchComments, postComment, editComment, deleteComment } from 'services/commentsService';
import CommentsContext from 'context/CommentsContext';
import AccountSettingsContext from 'context/AccountSettingsContext';

const CommentsContainer = ({ children, loader, requestId, requestTitle, requestOwner }) => {
  const [comments, setComments] = useState();
  const { accountSettings } = useContext(AccountSettingsContext);
  const ownerType = get(requestOwner, 'type');
  const ownerEmail = get(requestOwner, 'email');
  const ownerName = get(requestOwner, 'name');

  const addComment = async(comment, questionId, questionTitle, currentUser) => {
    const newComment = await postComment(questionId, comment);
    setComments({
      ...comments,
      [questionId]: comments[questionId].concat(newComment),
    });

    triggerNotifications({
      eventName: NOTIFICATIONS_EVENTS_NAMES.USER_ADDED_TRAINING_REQUEST_COMMENT,
      email: currentUser.attributes.email,
      accountId: currentUser.attributes['custom:accountId'],
      origin: NOTIFICATIONS_ORIGIN,
      data: {
        title: requestTitle,
        author: `${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`,
        question: {
          title: questionTitle,
          id: questionId,
        },
        requestId: requestId,
        ownerEmail,
        ownerName,
        ownerType,
      },
      emailerData: {
        companyLogo: get(accountSettings, 'branding.logo.url', '') || SYNAPSE_LOGO,
        submittedAt: moment(new Date()).format('ddd D MMM, h:mm A'),
        comment: comment,
      },
    });
  };

  const updateComment = async (questionId, commentId, commentContent) => {
    await editComment(commentId, { content: commentContent });
    const questionComments = comments[questionId];
    const foundCommentIndex = questionComments.findIndex(comment => comment.id === commentId);
    if (foundCommentIndex > -1) {
      const updatedComment = {
        ...questionComments[foundCommentIndex],
        content: commentContent,
      };
      const updatedQuestionComments = [
        ...questionComments.slice(0, foundCommentIndex),
        updatedComment,
        ...questionComments.slice(foundCommentIndex + 1),
      ];
      const updatedComments = {
        ...comments,
        [questionId]: updatedQuestionComments,
      };
      setComments(updatedComments);
    }
  };

  const removeComment = async ({ questionId, commentId }) => {
    await deleteComment(commentId);
    const questionComments = comments[questionId];
    const updatedQuestionComments = questionComments.filter(comment => comment.id !== commentId);
    const updatedComments = {
      ...comments,
      [questionId]: updatedQuestionComments,
    };
    setComments(updatedComments);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchComments(requestId);
      setComments(data);
    };
    fetchData();
  }, [requestId]);

  if (comments) {
    return (
      <CommentsContext.Provider
        value={{
          comments,
          addComment,
          updateComment,
          removeComment,
        }}
      >
        {children}
      </CommentsContext.Provider>
    );
  }

  return loader();
};

CommentsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  loader: PropTypes.func.isRequired,
  requestId: PropTypes.string.isRequired,
  requestTitle: PropTypes.string.isRequired,
  requestOwner: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
};

CommentsContainer.propTypes = {
  requestOwner: { type: 'none' },
};

export default CommentsContainer;
