import intl from 'react-intl-universal';
import moment from 'moment';

export const formatDate = notification => {
  return {
    ...notification,
    createdAt: moment(notification.createdAt).fromNow(),
  }
};

export const getNotificationsList = notifications => {
  return [
    {
      tag: intl.get('COLLABORATION.NOTIFICATIONS.UNREAD_HEADER'),
      items: notifications.filter(element =>
        element.seen === false,
      ).map(notification => formatDate(notification)),
    },
    {
      tag: intl.get('COLLABORATION.NOTIFICATIONS.READ_HEADER'),
      items: notifications.filter(element =>
        element.seen === true,
      ).map(notification => formatDate(notification)),
    },
  ]
};

export const checkNewAlerts = notifications => {
  const hasUnseenNotifications = notifications.some(notification => !notification.seen);
  return hasUnseenNotifications;
}
