import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { confirmUser } from 'services/authenticationService';
import { REDIRECTS } from 'helpers/constants';
import SnackBarContext from 'context/SnackBarContext';
import AccountSettingsContext from 'context/AccountSettingsContext';
import styles from './ConfirmAccount.module.scss';

const ConfirmAccount = () => {
  const location = useLocation();
  const history = useHistory();
  const { setSnackBarMessage } = useContext(SnackBarContext);
  const { accountId } = useContext(AccountSettingsContext);

  useEffect(() => {
    const sendConfirmUser = async () => {
      const query = new URLSearchParams(location.search);
      const response = await confirmUser(query.get('username'), query.get('code'));
      if(response.error) {
        setSnackBarMessage(response.message);
      } else {
        setSnackBarMessage(response);
      };
      history.push(`${REDIRECTS.LOG_IN}?accountId=${accountId}`);
    }
    sendConfirmUser();
  },[location, history, setSnackBarMessage, accountId]);

  return (
    <div className={styles.loaderContainer}>
      <CircularProgress size={100} thickness={7}/>
    </div>
  );
}

export default ConfirmAccount;
