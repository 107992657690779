import React from 'react';
import intl from 'react-intl-universal';
import styles from './PromptText.module.scss';

const PromptText = () => (
  <React.Fragment>
    <div className={styles.title}>
      {intl.get('RESET_PASSWORD_PAGE.PROMPT')}
    </div>
    <div className={styles.message}>
      {intl.get('RESET_PASSWORD_PAGE.MESSAGE')}
    </div>
  </React.Fragment>
);

export default PromptText;
