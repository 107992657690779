import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { fethAccountForms } from 'services/formsService';
import { fetchRequests } from 'services/requestsService';
import RequestsContext from 'context/RequestsContext';
import SessionContext from 'context/SessionContext';

const RequestsContainer = ({ children }) => {
  const [userRequests, setUserRequests] = useState([]);
  const [accountForms, setAccountForms] = useState([]);
  const { currentUser } = useContext(SessionContext);

  useEffect(() => {
    const fetchUserRequests = async () => {
      const requests = await fetchRequests(currentUser.attributes);
      setUserRequests(requests);
    };

    fetchUserRequests();
  }, [currentUser.attributes]);

  useEffect(() => {
    const fetchForms = async () => {
      const forms = await fethAccountForms(get(currentUser, 'attributes["custom:accountId"]', ''));
      setAccountForms(forms);
    };
    fetchForms();
  }, [currentUser]);

  const addRequest = (newRequest) => {
    const updatedRequests = userRequests.concat(newRequest);
    setUserRequests(updatedRequests);
  };

  const replaceRequest = (updatedRequest) => {
    const requestIndex = userRequests.findIndex(request => request.id === updatedRequest.id);
    const updatedRequests = [
      ...userRequests.slice(0, requestIndex),
      updatedRequest,
      ...userRequests.slice(requestIndex + 1),
    ];

    setUserRequests(updatedRequests);
  };

  const removeRequest = (requestId) => {
    const updatedRequests = userRequests.filter(request => request.id !== requestId);
    setUserRequests(updatedRequests);
  };

  return (
    <RequestsContext.Provider
      value={{
        userRequests,
        forms: accountForms,
        addRequest,
        replaceRequest,
        removeRequest,
      }}
    >
      { children }
    </RequestsContext.Provider>
  )
};

RequestsContainer.propTypes = {
  children: PropTypes.element.isRequired,
}

export default RequestsContainer
