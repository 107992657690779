import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import ErrorText from './components/ErrorText/ErrorText';
import styles from './HelperTextCard.module.scss';

const HelperTextCard = ({ errors, className }) =>
  <div className={classnames(styles.helperCard, className)}>
    <div className={styles.promptText}>
      {intl.get('PASSWORD_CRITERIA.PROMPT_TEXT')}
    </div>
    <div className={styles.errorHolder}>
      <ErrorText
        helperText={intl.get('PASSWORD_CRITERIA.MIN_LENGTH')}
        hasError={errors.lengthError}
      />
      <ErrorText
        helperText={intl.get('PASSWORD_CRITERIA.CONTAIN_LOWERCASE')}
        hasError={errors.lowercaseError}
      />
      <ErrorText
        helperText={intl.get('PASSWORD_CRITERIA.CONTAIN_UPPERCASE')}
        hasError={errors.uppercaseError}
      />
      <ErrorText
        helperText={intl.get('PASSWORD_CRITERIA.CONTAIN_NUMBER')}
        hasError={errors.numberError}
      />
      <ErrorText
        helperText={intl.get('PASSWORD_CRITERIA.CONTAIN_SPECIAL')}
        hasError={errors.specialCharacterError}
      />
    </div>
  </div>

HelperTextCard.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.shape({
    lengthError: PropTypes.bool,
    lowercaseError: PropTypes.bool,
    uppercaseError: PropTypes.bool,
    numberError: PropTypes.bool,
    specialCharacterError: PropTypes.bool,
  }),
};

HelperTextCard.defaultProps = {
  className: undefined,
  errors: {
    lengthError: false,
    lowercaseError: false,
    uppercaseError: false,
    numberError: false,
    specialCharacterError: false,
  },
};
export default HelperTextCard;
