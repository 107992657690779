import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { REDIRECTS } from 'helpers/constants';
import RequestsPage from 'components/RequestsPage/RequestsPage';
import LoginPage from 'components/LoginPage/LoginPage';
import ForgotPasswordPage from 'components/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from 'components/ResetPasswordPage/ResetPasswordPage';
import SignUpPage from 'components/SignUpPage/SignUpPage';
import ConfirmAccount from 'components/ConfirmAccount/ConfirmAccount';
import SSORedirectPage from 'components/SSORedirectPage/SSORedirectPage';
import CallbackPage from 'components/CallbackPage/CallbackPage';
import IeNotSupported from 'components/IeNotSupported/IeNotSupported';
import SessionContainer from 'containers/SessionContainer';
import SnackBarContainer from 'containers/SnackBarContainer';
import AccountSettingsContainer from 'containers/AccountSettingsContainer';
import AccountIdSetterContainer from 'containers/AccountIdSetterContainer';

const App = () =>
  <Router>
    <AccountSettingsContainer>
      <SnackBarContainer>
        <Route path='/requests'>
          <SessionContainer>
            <RequestsPage />
          </SessionContainer>
        </Route>
        <AccountIdSetterContainer>
          <Route path={REDIRECTS.LOG_IN}>
            <LoginPage />
          </Route>
          <Route path={REDIRECTS.FORGOT_PASSWORD}>
            <ForgotPasswordPage />
          </Route>
          <Route path={REDIRECTS.RESET_PASSWORD}>
            <ResetPasswordPage />
          </Route>
          <Route path={REDIRECTS.REGISTER}>
            <SignUpPage/>
          </Route>
          <Route path='/confirm'>
            <ConfirmAccount/>
          </Route>
          <Route path='/ssoLoading'>
            <SSORedirectPage/>
          </Route>
          <Route path='/callback'>
            <CallbackPage/>
          </Route>
        </AccountIdSetterContainer>
        <Route exact path='/'>
          <Redirect to={REDIRECTS.REQUESTS} />
        </Route>
        <Route path={REDIRECTS.IE_NOT_SUPPORTED}>
          <IeNotSupported/>
        </Route>
      </SnackBarContainer>
    </AccountSettingsContainer>
  </Router>;

export default App;
