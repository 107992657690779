import prodConfig from './prod.json';
import stgConfig from './stg.json';
import devConfig from './dev.json';
import testConfig from './test.json';
import e2eConfig from './e2e.json';
import caConfig from './ca.json';

const envFiles = new Map([
  ['prod', prodConfig],
  ['stg', stgConfig],
  ['dev', devConfig],
  ['test', testConfig],
  ['e2e', e2eConfig],
  ['ca', caConfig],
]);

class JsonConfig {
  constructor (env) {
    this._env = env;
  }

  getConfig () {
    return envFiles.get(this._env) || {};
  }
}

export default new JsonConfig(process.env.REACT_APP_APP_ENV);
