import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button } from 'synapse-ui';
import styles from './SubmitModal.module.scss';

const SubmitModal = ({ onSubmit, onClose }) =>
  <div className={styles.submitModal}>
    <div className={styles.title}>{ intl.get('SUBMIT_REQUEST.TITLE') }</div>
    <div className={styles.description}>{ intl.get('SUBMIT_REQUEST.MESSAGE') }</div>
    <div className={styles.actionButtons}>
      <Button
        className={styles.cancelButton}
        onClick={onClose}
      >
        { intl.get('SUBMIT_REQUEST.CANCEL_BUTTON') }
      </Button>
      <Button
        className={styles.submitButton}
        variant='contained'
        onClick={onSubmit}
        data-testid='submit-modal__confirm-button'
      >
        { intl.get('SUBMIT_REQUEST.SUBMIT_BUTTON') }
      </Button>
    </div>
  </div>;

SubmitModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SubmitModal;
