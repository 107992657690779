import React, { useEffect, useState, useContext, Fragment } from 'react';
import intl from 'react-intl-universal';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Branding } from 'synapse-ui';
import defaultBackground from 'assets/images/DefaultBackground.jpg';
import { REDIRECTS } from 'helpers/constants';
import AccountSettingsContext from 'context/AccountSettingsContext';
import Logo from 'components/components/Logo/Logo';
import SignUpForm from './components/SignUpForm/SignUpForm';
import SnackBarContext from 'context/SnackBarContext';
import styles from './SignUpPage.module.scss';
import emailIcon from 'assets/images/email.png';

const SignUpPage = ({instructions}) => {
  const location = useLocation();
  const history = useHistory();
  const { setSnackBarMessage } = useContext(SnackBarContext);
  const [accountId, setAccountId] = useState('');
  const { accountSettings } = useContext(AccountSettingsContext);
  const background = get(accountSettings, 'branding.backgroundImage.url');
  const [successfulRegistration, setSuccessfulRegistration] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const account = query.get('accountId');
    const successful= query.get('successful');
    if (successful) {
      setSuccessfulRegistration(true);
    }
    setAccountId(account);
    if (!account) {
      setSnackBarMessage(intl.get('SIGN_UP_PAGE.INVALID_URL'));
      history.push(REDIRECTS.LOG_IN);
    }
  }, [history, setSnackBarMessage, location]);

  return (
    <div className={styles.signUpPage}>
      <div className={styles.signUpForm}>
        <Logo/>
        { !successfulRegistration && <div className={styles.signUpTitle}>
          {intl.get('SIGN_UP_PAGE.REGISTRATION_TITLE')}
        </div>
        }
        { !successfulRegistration && <div className={styles.signUpInstructions}>
          {
            instructions ?
              instructions :
              intl.getHTML('SIGN_UP_PAGE.INSTRUCTIONS', {
                instructions: styles.instructions,
                requiredFields: styles.requiredFields,
              })
          }
        </div>
        }
        { !successfulRegistration ? 
          <SignUpForm accountId={accountId}/> :
          <Fragment>
            <div className={styles.confirmationTitle}>
              {intl.get('SIGN_UP_PAGE.CONFIRMATION_TITLE')}
            </div>
            <div className={styles.confirmationInstructions}>
              {intl.get('SIGN_UP_PAGE.CONFIRMATION_INSTRUCTIONS')}
            </div>
            <div className={styles.emailIcon}>
              {intl.getHTML('SIGN_UP_PAGE.EMAIL_ICON', {icon: emailIcon})}
            </div>
          </Fragment>
        }
        <Branding className={styles.branding}/>
      </div>
      <div
        className={styles.signUpImage}
        style={{backgroundImage: background ? `url(${background})` : `url(${defaultBackground}`}}
      />
    </div>
  );
}

SignUpPage.propTypes = {
  instructions: PropTypes.string,
};

SignUpPage.defaultProps = {
  instructions: '',
};
export default SignUpPage;
