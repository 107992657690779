import axios from 'axios';
import config from 'environment/Config';

export const getSignedUrl = async (url) => {
  try{
    const { data } = await axios.post(
      'cloudfront/permission',
      { payload: { url } },
      { baseURL: config.getValue('backendURL')},
    );
    return data.payload.signedUrl;
  } catch (error) {
    return {error: true};
  }
};
