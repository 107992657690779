import React from 'react';
import intl from 'react-intl-universal';
import styles from './PromptText.module.scss';
import PropTypes from 'prop-types';
import config from 'environment/Config';
import icon from 'assets/images/Intercom.png';

const PromptText = ({ hasMessage }) => (
  <React.Fragment>
    <div className={styles.title}>
      {intl.get('FORGOT_PASSWORD_PAGE.PROMPT')}
    </div>
    <div className={styles.message}>
      { hasMessage ? intl.getHTML('FORGOT_PASSWORD_PAGE.SUCCESSFUL_MESSAGE', { link: config.getValue('synapseAccountURL'), intercomIcon: icon }) : intl.get('FORGOT_PASSWORD_PAGE.MESSAGE')}
    </div>
  </React.Fragment>
);

PromptText.propTypes = {
  hasMessage: PropTypes.bool,
};

PromptText.defaultProps = {
  hasMessage: false,
};

export default PromptText;
