import axios from 'axios';
import { getCurrentUser, initAmplify } from 'services/authenticationService';
import {
  HTTP_STATUS,
  REDIRECTS,
} from 'helpers/constants';
import config from 'environment/Config';

export const initAxios = async () => {
  initAmplify();
  axios.defaults.baseURL = config.getValue('backendURL');
  const { error, user } = await getCurrentUser();
  if (!error) {
    addAuthorizationHeader(user.signInUserSession.idToken.jwtToken);
  }
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
        //! TODO: replace this for an error boundry flow to be handled in react
        window.location.href = REDIRECTS.LOG_IN;
      }
      return error;
    },
  );
};

export const addAuthorizationHeader = (token) => {
  axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
};

export const removeAuthorizationHeader = () => {
  axios.defaults.headers.common['Authorization'] = undefined;
};
