import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button } from 'synapse-ui';
import { REDIRECTS } from 'helpers/constants';
import AccountSettingsContext from 'context/AccountSettingsContext';
import FormInput from 'components/components/FromInput/FormInput';
import HelperTextCard from 'components/components/HelperTextCard/HelperTextCard';
import passwordValidator from 'helpers/passwordValidator';
import styles from './PasswordInputs.module.scss';
import { resetPassword } from 'services/authenticationService';

const PasswordInputs = () => {
  const history = useHistory();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const { accountId } = useContext(AccountSettingsContext);
  const [newPasswordEmpty, setNewPasswordEmpty] = useState(true);
  const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(true);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [confirmError, setConfirmError] = useState('');
  const [passwordErrors, setPasswordErrors] = useState({});
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const username = query.get('username');
    const code = query.get('code');
    if (username && code) {
      setUsername(username);
      setCode(code);
    }
  }, [setUsername, setCode, location]);

  const handleNewPasswordChange = event => {
    const isNewPasswordEmpty = isEmpty(event.target.value);
    if (isNewPasswordEmpty !== newPasswordEmpty) {
      setNewPasswordEmpty(isNewPasswordEmpty);
    }
    setConfirmError('');
    setHasPasswordError(false);
  };

  const handleConfirmPasswordChange = event => {
    const isConfirmPasswordEmpty = isEmpty(event.target.value);
    if (isConfirmPasswordEmpty !== confirmPasswordEmpty) {
      setConfirmPasswordEmpty(isConfirmPasswordEmpty);
    }
    setConfirmError('');
    setHasPasswordError(false);
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    if (confirmPasswordRef.current.value && newPasswordRef.current.value !== confirmPasswordRef.current.value) {
      setConfirmError(intl.get('RESET_PASSWORD_PAGE.PASSWORD_ERROR'));
    }
    const { hasError, errors } = await passwordValidator(newPasswordRef.current.value);
    if (hasError) {
      setHasPasswordError(true);
      setPasswordErrors(errors);
    } else {
      setHasPasswordError(false);
      if (newPasswordRef.current.value === confirmPasswordRef.current.value) {
        resetPassword(username, code, newPasswordRef.current.value).then(() => {
          history.push(`${REDIRECTS.LOG_IN}?accountId=${accountId}`);
        }).catch(() => {
        });
      }
    }
  };

  return (
    <React.Fragment>
      <FormInput
        password
        label={intl.get('RESET_PASSWORD_PAGE.NEW_PASSWORD')}
        placeholder={intl.get('RESET_PASSWORD_PAGE.NEW_PASSWORD_PLACEHOLDER')}
        isEmpty={newPasswordEmpty}
        inputRef={newPasswordRef}
        onChange={handleNewPasswordChange}
      />
      {hasPasswordError &&
        <HelperTextCard className={styles.errorCard} errors={passwordErrors} />
      }
      <form onSubmit={handlePasswordReset}>
        <FormInput
          password
          label={intl.get('RESET_PASSWORD_PAGE.CONFIRM_NEW_PASSWORD')}
          placeholder={intl.get(
            'RESET_PASSWORD_PAGE.CONFIRM_NEW_PASSWORD_PLACEHOLDER',
          )}
          isEmpty={confirmPasswordEmpty}
          onChange={handleConfirmPasswordChange}
          inputRef={confirmPasswordRef}
          hasError={confirmError !== ''}
          helperText={confirmError}
        />
        <Button
          className={styles.setPasswordButton}
          color='primary'
          variant='contained'
          type='submit'
          onClick={handlePasswordReset}
          disabled={newPasswordEmpty || confirmPasswordEmpty}
        >
          {intl.get('RESET_PASSWORD_PAGE.SET_NEW_PASSWORD')}
        </Button>
      </form>
    </React.Fragment>
  );
};

PasswordInputs.propTypes = {
  verifiedEmail: PropTypes.string,
};
export default PasswordInputs;
