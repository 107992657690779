import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import AccountSettingsContext from 'context/AccountSettingsContext';
import { fetchAccountSettings } from 'services/accountService';
import { getCurrentUser } from 'services/authenticationService';
import { fetchEnterpriseConnection } from 'services/enterpriseConnectionsService';

const AccountSettingsContainer = ({ children }) => {
  const [accountSettings, setAccountSettings] = useState({});
  const [enterpriseConnection, setEnterpriseConnection] = useState({});
  const [accountId, setAccountId] = useState('');

  useEffect(() => {
    const init = async () => {
      if (accountId) {
        const accountSettings = await fetchAccountSettings(accountId);
        setAccountSettings(get(accountSettings, 'config.configuration.system', {}));

        const { user } = await getCurrentUser();

        if (isEmpty(user)) {
          const enterpriseConnection = await fetchEnterpriseConnection(accountId);
          setEnterpriseConnection(enterpriseConnection);
        }
      }
    }

    init();
  },[accountId]);

  return (
    <AccountSettingsContext.Provider
      value={{
        accountSettings,
        setAccountId,
        accountId,
        enterpriseConnection,
      }}
    >
      { children }
    </AccountSettingsContext.Provider>
  )
};

AccountSettingsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default AccountSettingsContainer
