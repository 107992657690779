import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { DropdownPreview as Dropdown } from 'synapse-ui';

const DropdownQuestion = ({ isDisabled, onChange, question }) =>
  <Dropdown
    SelectDisplayProps={{
      'data-testid': 'flexible-card__dropdown_popover-trigger',
    }}
    MenuProps={{
      MenuListProps: {
        'data-testid': 'flexible-card__popover-menu',
      },
    }}
    options={get(question, 'data.value.items', [])}
    defaultSelected={question.data.value.value}
    isDisabled={isDisabled}
    onChange={(newValue) => onChange(newValue, 'data.value.value')}
  />;

DropdownQuestion.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({
          value: PropTypes.string.isRequired,
          display: PropTypes.string.isRequired,
        })).isRequired,
        value: PropTypes.object,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default DropdownQuestion;
