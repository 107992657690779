import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'synapse-ui';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import Logo from 'components/components/Logo/Logo';
import SideBarExpansionPanel from './components/SideBarExpansionPanel/SideBarExpansionPanel';
import NewRequestModalTrigger from 'components/RequestsPage/components/NewRequestModal/ModalTrigger/ModalTrigger';
import styles from './Sidebar.module.scss';

const SideBar = ({requests}) => (
  <div className={styles.sideBar}>
    <div className={styles.sedebarHeaderContainer}>
      <Logo />
      <hr className={styles.separator}/>
      <div className={styles.sideBarHeader}>
        <div>{intl.get('SIDE_BAR.TRAINING_REQUESTS')}</div>
        <NewRequestModalTrigger
          renderTrigger={({ openModal }) =>
            <Button
              className={styles.createRequestButton}
              onClick={openModal}
              variant='contained'
              color='primary'
              data-testid='sideBar__add-request-button'
            >
              <Icon name='ion-md-add' size='small'/>
            </Button>
          }
        />
      </div>
    </div>

    <div className={styles.expansionPanelContainer}>
      { !isEmpty(requests) ?
        Object.keys(requests).map((status, index) =>
          <SideBarExpansionPanel
            key={index}
            heading={intl.get(`SIDE_BAR.${status.toUpperCase()}`)}
            requests={requests[status]}
            status={status}
          />,
        )
        :
        <div className={styles.sidebarEmpty}>
          <Icon className={styles.sidebarEmptyIcon} name='ion-md-alert' size='large'/>
          <div className={styles.emptyStateText}> {intl.get('SIDE_BAR.EMPTY_STATE')} </div>
        </div>
      }
    </div>
  </div>
);

const request = {
  id: PropTypes.string,
  accountId: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
  }),
  owner: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  status: PropTypes.string,
  type: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

SideBar.propTypes = {
  requests: PropTypes.shape({
    approved: PropTypes.arrayOf(PropTypes.shape(request)).isRequired,
    draft: PropTypes.arrayOf(PropTypes.shape(request)).isRequired,
    rejected: PropTypes.arrayOf(PropTypes.shape(request)).isRequired,
  }).isRequired,
};

export default SideBar;
