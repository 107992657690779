import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import {
  fetchNotifications,
  markAllAsRead,
  updateNotification,
} from 'services/notifications';
import NotificationsContext from 'context/NotificationsContext';
import SessionContext from 'context/SessionContext';

const NotificationsContainer = ({ children, loader }) => {
  const [notifications, setNotifications] = useState();
  const { currentUser } = useContext(SessionContext);

  useEffect(() => {
    const init = async () => {
      const data = await fetchNotifications(currentUser.attributes.email);
      setNotifications(data);
    };
    init(); //to load right away instead of waiting a minute
    const interval = setInterval(() => init(), 60 * 1000);
    return () => clearInterval(interval);
  }, [currentUser.attributes.email]);

  const onUpdateNotification = async (notificationId, seen) => {
    await updateNotification(notificationId, seen);
    const foundNotificationIndex = notifications.findIndex(notification => notification.id === notificationId);
    if (foundNotificationIndex === -1) {
      return;
    }
    const clonedNotifications = cloneDeep(notifications);
    clonedNotifications[foundNotificationIndex] = {
      ...notifications[foundNotificationIndex],
      seen,
    };
    setNotifications(clonedNotifications);
  };

  const onMarkAllAsRead = async email => {
    await markAllAsRead(email);
    const updatedNotifications = notifications.map(notification => ({...notification, seen: true}));
    setNotifications(updatedNotifications);
  };

  if (notifications) {
    return (
      <NotificationsContext.Provider
        value={{
          notifications,
          onUpdateNotification,
          onMarkAllAsRead,
        }}
      >
        { children }
      </NotificationsContext.Provider>
    );
  }

  return loader();
};

NotificationsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  loader: PropTypes.func.isRequired,
};

export default NotificationsContainer;
