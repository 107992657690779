import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { DatePreview as Date } from 'synapse-ui';

const DateQuestion = ({ isDisabled, onChange, question }) =>
  <Date
    KeyboardButtonProps={{
      'data-testid': 'flexible-card__date__popover-trigger',
    }}
    inputProps={{ 'data-testid': 'flexible-card__date__text-field' }}
    defaultValue={get(question, 'data.value', '')}
    isDisabled={isDisabled}
    onChange={(newValue) => onChange(newValue, 'data.value')}
  />;

DateQuestion.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default DateQuestion;
