import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import styles from './RequestMessage.module.scss';

const RequestMessage = ({ message }) =>
  message.content !== '' ?
    <div className={styles.message}>
      <div className={styles.title}>
        {intl.getHTML('REQUEST.MESSAGE', { author: message.author })}
      </div>
      <div className={styles.content}>
        {message.content}
      </div>
    </div>
    : null

RequestMessage.propTypes = {
  message: PropTypes.shape({
    author: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
}

export default RequestMessage;
