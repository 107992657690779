import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty, get, cloneDeep } from 'lodash';
import Cookies from 'js-cookie';
import { getCurrentUser } from 'services/authenticationService';
import { fetAccountAdminUsers, fetchAccount } from 'services/accountService';
import { initPendo } from 'services/pendo';
import SessionContext from 'context/SessionContext';
import AccountSettingsContext from 'context/AccountSettingsContext';

const SessionContainer = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [adminUsers, setAdminUsers] = useState([]);
  const { setAccountId, accountId } = useContext(AccountSettingsContext);
  const history = useHistory();
  const location = useLocation();

  const fetchAdminUsers = useCallback(async () => {
    if (accountId) {
      const accountAdminUsers = await fetAccountAdminUsers(accountId);
      setAdminUsers(accountAdminUsers);
    }
  }, [accountId]);

  useEffect(() => {
    const verifyUser = async () => {
      const { error, user } = await getCurrentUser();
      if (!error) {
        const curUser = cloneDeep(user);
        curUser.attributes.email = get(user, 'attributes.email', '').toLowerCase();
        
        setCurrentUser(curUser);
        setAccountId(user.attributes['custom:accountId']);
        fetchAdminUsers();
      } else {
        const urlParams = new URLSearchParams(location.search);
        const accountId = urlParams.get('accountId');
        Cookies.set('redirectUrlCallback', `${location.pathname}${location.search}`);
        if (accountId) {
          history.push(`/login?accountId=${accountId}`);
        }
        else {
          history.push('/login');
        }
      }
    };
    verifyUser();
  }, [history, setAccountId, fetchAdminUsers, location.pathname, location.search]);

  useEffect(() => {
    if (currentUser) {
      const getUserData = async () => {
        const accountId = get(currentUser, 'attributes[custom:accountId]', '');
        const email = get(currentUser, 'attributes.email', '');
        const name = get(currentUser, 'attributes.given_name', '');
        const lastName = get(currentUser, 'attributes.family_name', '');
        const account = await fetchAccount(accountId);
        const accountName = get(account, 'name', '');

        initPendo(email, `${name} ${lastName}`, accountId, accountName);
      };

      getUserData();
    }
  }, [currentUser, fetchAccount]);

  const changeCurrentUser = (newCurrentUser) => {
    setCurrentUser(newCurrentUser)
    if (isEmpty(newCurrentUser)) {
      history.push(`/login?accountId=${accountId}&logout=true`);
    }
  };

  if (isEmpty(currentUser)) {
    return null;
  }

  return (
    <SessionContext.Provider
      value={{
        currentUser,
        changeCurrentUser,
        adminUsers,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default SessionContainer;
