import React, { useContext } from 'react';
import get from 'lodash/get';
import { Branding } from 'synapse-ui';
import defaultBackground from 'assets/images/DefaultBackground.jpg';
import AccountSettingsContext from 'context/AccountSettingsContext';
import Logo from 'components/components/Logo/Logo';
import WelcomeText from './components/WelcomeText/WelcomeText';
import LoginForm from './components/LoginForm/LoginForm';
import styles from './LoginPage.module.scss';

const LoginPage = () => {
  const { accountSettings } = useContext(AccountSettingsContext);
  const background = get(accountSettings, 'branding.backgroundImage.url');
  const welcomeText = get(accountSettings, 'branding.welcomeText', '');

  return (
    <div className={styles.loginPage}>
      <div className={styles.interactSection}>
        <Logo />
        <WelcomeText welcomeText={welcomeText}/>
        <LoginForm />
        <Branding className={styles.branding} />
      </div>
      <div
        className={styles.backgroundImage}
        style={{backgroundImage: background ? `url(${background})` : `url(${defaultBackground}`}}
      />
    </div>
  );
};

export default LoginPage;
