import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SnackBar from 'components/components/SnackBar/SnackBar';
import SnackBarContext from 'context/SnackBarContext';

const SnackBarContainer = ({ children }) => {
  const [snackBarMessage, setSnackBarMessage] = useState();
  return (
    <SnackBarContext.Provider
      value={{
        snackBarMessage,
        setSnackBarMessage,
      }}
    >
      <SnackBar />
      { children }
    </SnackBarContext.Provider>
  )
};

SnackBarContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default SnackBarContainer
