import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { fetchRequest, updateRequest } from 'services/requestService';
import RequestContext from 'context/RequestContext';

const RequestContainer = ({ children, loader }) => {
  const { requestId } = useParams();
  const [request, setRequest] = useState();

  useEffect(() => {
    // TODO: Replace this fetch with a selector from requests list
    fetchRequest(requestId)
      .then(setRequest);
  }, [requestId]);

  const updateRequestTitle = async (newTitle) => {
    const newData = {...request.data};
    newData.requestTitle = newTitle;
    const updatedRequest = await updateRequest(request.id, { data: newData })
    setRequest(updatedRequest);
  }

  if (request) {
    return (
      <RequestContext.Provider
        value={{
          request,
          setRequest,
          updateRequestTitle,
        }}
      >
        {children}
      </RequestContext.Provider>
    );
  }

  return loader();
};

RequestContainer.propTypes = {
  children: PropTypes.node.isRequired,
  loader: PropTypes.func.isRequired,
};

export default RequestContainer;
