import intl from 'react-intl-universal';
import { COMMENTS_ACTIONS } from 'helpers/constants'

export const returnCommentsProps = (comments, questionId, currentUser) => {
  return {
    questionId: `${questionId}comment`,
    commentData: {
      placeholder: intl.get('COLLABORATION.COMMENT.COMMENT_PLACEHOLDER'),
      cancelButtonText: intl.get('COLLABORATION.COMMENT.CANCEL_BUTTON'),
      commentButtonText: intl.get('COLLABORATION.COMMENT.COMMENT_BUTTON'),
      replyButtonText: intl.get('COLLABORATION.COMMENT.REPLY_BUTTON'),
      updateButtonText: intl.get('COLLABORATION.COMMENT.UPDATE_BUTTON'),
      isAdminText: intl.get('COLLABORATION.COMMENT.IS_ADMIN'),
      user: `${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`,
    },
    comments: comments || [],
  }
}

export const returnCommentsActions = (onEditComment, onDeleteComment) => {
  return [
    {
      type: COMMENTS_ACTIONS.EDIT.TYPE,
      label: intl.get('COLLABORATION.COMMENT.EDIT'),
      icon: COMMENTS_ACTIONS.EDIT.ICON,
      callback: onEditComment,
    },
    {
      type: COMMENTS_ACTIONS.REMOVE.TYPE,
      label: intl.get('COLLABORATION.COMMENT.REMOVE'),
      icon: COMMENTS_ACTIONS.REMOVE.ICON,
      callback: onDeleteComment,
    },
  ];
};
