import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import RequestsContext from 'context/RequestsContext';

const REQUESTS_STATUS = {
  DRAFT: 'draft',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const SideBarContainer = ({render}) => {
  const { userRequests } = useContext(RequestsContext);

  const filteredRequest = useMemo(() => {
    if(userRequests.length) {
      const requests = {
        [REQUESTS_STATUS.DRAFT]: [],
        [REQUESTS_STATUS.SUBMITTED]: [],
        [REQUESTS_STATUS.APPROVED]: [],
        [REQUESTS_STATUS.REJECTED]: [],
      };
      userRequests.forEach(req => {
        const request = {
          id: req.id,
          updatedAt: req.updatedAt,
          title: get(req, 'data.requestTitle', get(req, 'data.title', '')),
          status: req.status,
        };
        requests[req.status].push(request);
      });
    
      return requests;
    }
    return {};
  }, [userRequests]);

  return render({ requests: filteredRequest });
};

SideBarContainer.propTypes = {
  loader: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
};

export default SideBarContainer;
