import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Button } from 'synapse-ui';
import RequestContext from 'context/RequestContext';
import RequestsContext from 'context/RequestsContext';
import SnackBarContext from 'context/SnackBarContext';
import SessionContext from 'context/SessionContext';
import { NOTIFICATIONS_EVENTS_NAMES, NOTIFICATIONS_ORIGIN } from 'helpers/constants';
import { triggerNotifications } from 'services/notifications';
import { deleteRequest } from 'services/requestService';
import DeleteModalTrigger from '../../../DeleteModal/DeleteModalTrigger/DeleteModalTrigger';
import styles from './DeleteButton.module.scss';

const DeleteButton = ({ request }) => {
  const history = useHistory();
  const { setRequest } = useContext(RequestContext);
  const { removeRequest } = useContext(RequestsContext);
  const { setSnackBarMessage } = useContext(SnackBarContext);
  const { currentUser } = useContext(SessionContext);
  const [isDeleting, setIsDeleting] = useState(false);
  
  const onDelete = async() => {
    setIsDeleting(true);
    await deleteRequest(request.id)
    setIsDeleting(false);
    setRequest(undefined);
    removeRequest(request.id)
    triggerNotifications({
      eventName: NOTIFICATIONS_EVENTS_NAMES.REQUEST_DELETED,
      email: currentUser.attributes.email,
      accountId: currentUser.attributes['custom:accountId'],
      origin: NOTIFICATIONS_ORIGIN,
      data: {
        requestId: request.id,
      },
    });

    history.push('/requests');
    setSnackBarMessage(intl.get('SNACK_BAR.DELETE_CONFIRMATION'));
  };

  return (
    <DeleteModalTrigger 
      renderTrigger={({ openModal }) =>
        <Button
          className={styles.deleteButton}
          isLoading={isDeleting}
          onClick={openModal}
        >
          {intl.get('REQUEST_ACTIONS.DELETE')}
        </Button>
      }
      onDelete={onDelete}
    />
  );
};

DeleteButton.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteButton;
