import axios from 'axios';

export const fetchComments = async requestId => {
  try {
    const { data = {} } = await axios.get(`/requests/${requestId}/comments`);
    return data;
  } catch(error) {
    return {};
  }
};

export const postComment = async (questionId, content) => {
  try{
    const { data } = await axios.post(`/questions/${questionId}/comments`, {
      'content': content,
    });
    return data;
  } catch (error) {
    return {error: true, message: error}
  }
};

export const editComment = async (commentId, commentData) => {
  const { data } = await axios.put(`/comments/${commentId}`, commentData);

  return data;
};

export const deleteComment = async (commentId) => {
  const { data } = await axios.delete(`/comments/${commentId}`);

  return data;
};
