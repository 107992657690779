import React, { useState, useRef, useContext } from 'react';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { REDIRECTS, NOTIFICATIONS_EVENTS_NAMES, NOTIFICATIONS_ORIGIN } from 'helpers/constants';
import { triggerNotifications } from 'services/notifications';
import { signUp as signUpService } from 'services/authenticationService';
import passwordValidator from 'helpers/passwordValidator';
import SnackBarContext from 'context/SnackBarContext';
import { Button } from 'synapse-ui';
import FormInput from 'components/components/FromInput/FormInput';
import HelperTextCard from 'components/components/HelperTextCard/HelperTextCard';
import styles from './SignUpForm.module.scss';

const SignUpForm = ({accountId}) => {
  const history = useHistory();
  const email = useRef();
  const password = useRef();
  const firstName = useRef();
  const lastName = useRef();
  const { setSnackBarMessage } = useContext(SnackBarContext);
  const [passwordErrors, setPasswordErrors] = useState({
    hasError: false,
    errors: {},
  });
  const [userErrors, setUserErrors] = useState({
    email: false,
    password: false,
    firstName: false,
    lastName: false,
  });

  const handleSignUp = async (event) => {
    event.preventDefault();
    setUserErrors({
      email: !email.current.value,
      password: !password.current.value,
      firstName: !firstName.current.value,
      lastName: !lastName.current.value,
    });

    if(
      !userErrors.email &&
      !userErrors.password &&
      !userErrors.firstName &&
      !userErrors.lastName
    ) {
      if(!passwordErrors.hasError) {
        const response = await signUpService({
          email: email.current.value,
          password: password.current.value,
          firstName: firstName.current.value,
          lastName: lastName.current.value,
        }, accountId);
        if(response.error) {
          setSnackBarMessage(response.error.message);
        } else {
          triggerNotifications({
            eventName: NOTIFICATIONS_EVENTS_NAMES.USER_REGISTERED,
            email: email.current.value.toLowerCase(),
            accountId,
            origin: NOTIFICATIONS_ORIGIN,
            data: {
              firstName: firstName.current.value,
              lastName: lastName.current.value,
            },
          });

          history.push(`${REDIRECTS.REGISTER}?accountId=${accountId}&successful=true`);
        }
      }
    }
  }

  const validatePassword = async () => {
    const errors = await passwordValidator(password.current.value);
    setPasswordErrors(errors);
  }

  return (
    <form className={styles.signUpForm} onSubmit={handleSignUp}>
      <FormInput
        placeholder={intl.get('SIGN_UP_PAGE.FIRST_NAME_PLACEHOLDER')}
        inputRef={firstName}
        label={intl.get('SIGN_UP_PAGE.FIRST_NAME_LABEL')}
        hasError={userErrors.firstName}
        helperText={
          userErrors.firstName ?
            intl.get(
              'SIGN_UP_PAGE.ERROR_MESSAGE',
              {name: intl.get('SIGN_UP_PAGE.FIRST_NAME_LABEL')},
            )
            :
            ''
        }
        testId='signup-form__first-name'
      />
      <FormInput
        placeholder={intl.get('SIGN_UP_PAGE.LAST_NAME_PLACEHOLDER')}
        inputRef={lastName}
        label={intl.get('SIGN_UP_PAGE.LAST_NAME_LABEL')}
        hasError={userErrors.lastName}
        helperText={
          userErrors.lastName ?
            intl.get(
              'SIGN_UP_PAGE.ERROR_MESSAGE',
              {name: intl.get('SIGN_UP_PAGE.LAST_NAME_LABEL')},
            )
            :
            ''
        }
        testId='signup-form__last-name'
      />
      <FormInput
        placeholder={intl.get('SIGN_UP_PAGE.EMAIL_PLACEHOLDER')}
        inputRef={email}
        label={intl.get('SIGN_UP_PAGE.EMAIL_LABEL')}
        hasError={userErrors.email}
        helperText={
          userErrors.email ?
            intl.get(
              'SIGN_UP_PAGE.ERROR_MESSAGE',
              {name: intl.get('SIGN_UP_PAGE.EMAIL_LABEL')},
            )
            :
            ''
        }
        testId='signup-form__email'
      />
      <div>
        <FormInput
          className={styles.passwordInput}
          password
          label={intl.get('SIGN_UP_PAGE.PASSWORD_LABEL')}
          placeholder={intl.get('SIGN_UP_PAGE.PASSWORD_PLACEHOLDER')}
          inputRef={password}
          hasError={userErrors.password}
          onChange={validatePassword}
          testId='signup-form__password'
        />
        {
          passwordErrors.hasError &&
          <HelperTextCard
            className={styles.errorCard}
            errors={passwordErrors.errors}
          />
        }
      </div>
      <Button
        className={styles.signUpButton}
        color='primary'
        type='submit'
        variant='contained'
        onClick={handleSignUp}
      >
        {intl.getHTML('SIGN_UP_PAGE.REGISTER_BUTTON')}
      </Button>
    </form>
  );
};

SignUpForm.propTypes = {
  accountId: PropTypes.string.isRequired,
}
export default SignUpForm;
