import React, { useContext } from 'react';
import { SNACKBAR } from 'helpers/constants';
import intl from 'react-intl-universal';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import SnackBarContext from 'context/SnackBarContext';
import styles from './SnackBar.module.scss';

const SnackBar = () => {
  const { snackBarMessage, setSnackBarMessage } = useContext(SnackBarContext);
  const handleClose = () => {
    setSnackBarMessage('');
  };

  return (
    <Snackbar
      open={!!snackBarMessage}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={handleClose}
      autoHideDuration={SNACKBAR.AUTO_HIDDEN_TIME}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <SnackbarContent
        className={styles.snackbar}
        message={
          <span className={styles.message}>
            {snackBarMessage}
          </span>
        }
        action={
          <span className={styles.close} onClick={handleClose}>
            {intl.get('SNACK_BAR.CLOSE_BUTTON')}
          </span>
        }
      />
    </Snackbar>
  );
};

export default SnackBar;
