import { QUESTIONS_TYPES } from 'helpers/constants';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export const areRequiredQuestionsFilled = (questionsArray, request) => {
  if (!request.data.requestTitle) return false;
  if (questionsArray.length === 0) return false;
  const isThereAnEmptyQuestion = questionsArray
    .filter(question => {
      const questionType = get(question, 'type', QUESTIONS_TYPES.COMMENT);
      const isRequired = get(question, 'data.isRequired', true);
      return questionType !== QUESTIONS_TYPES.SECTION && isRequired;
    })
    .some(question => {
      const questionValue = question.data.value;
      switch (question.type) {
        case QUESTIONS_TYPES.RANGE:
          return questionValue.value === 0;
        case QUESTIONS_TYPES.CHECKBOX:
          return questionValue.items.some(item => item.checked) === false;
        case QUESTIONS_TYPES.RADIO_BUTTON:
          return questionValue.value === null;
        case QUESTIONS_TYPES.DROPDOWN:
          return questionValue.value === null;
        case QUESTIONS_TYPES.FILE_UPLOADER:
          return isEmpty(questionValue.value);
        case QUESTIONS_TYPES.DATE:
          return questionValue === 'Invalid date' || questionValue.length === 0
        default:
          return questionValue.length === 0;
      }
    });
  return !isThereAnEmptyQuestion;
};
